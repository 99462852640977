export interface WebPageMetadata {
    // title: string;
    page: string;
    title?: string;
    title_el?: string;
    description?: string;
    description_el?: string;
    keywords?: string;
    keywords_el?: string;
    image?: string;
    image_el?: string;
}

export const defaultKeywordsEN = `rentals, furnished apartments, accommodation, home, apartments, houses, apartment, house, properties, rental properties, rental property, rental, homes, housing, mid-term stays, stays, property, month-to-month rentals, monthly rentals, extended stays, mid-term stay, flexible lease, accommodation for a few months, monthly stays, villas, flats, studios, booking, deals, special offers, move-in ready, off-campus housing, digital nomads, homes for digital nomads, apartments for rent, apartments for rent in Greece, Greece, Heraklion, Athens, accommodation in Greece, fully furnished properties, rental properties in greece, properties for rent, apartments for students`;
export const defaultKeywordsEL = `ενοικιάσεις σπιτιών, σπίτια προς ενοικίαση, διαμέρισμα για ενοικίαση, ενοικίαση σπιτιού, διαμέρισμα, διαμονή, διαμερίσματα, ενοικιαζόμενα σπίτια, φοιτητικά σπίτια, ενοικιάσεις κατοικιών, καταλύματα, επιπλωμένα σπίτια, ψάχνω σπίτι, ιδιοκτήτες ακινήτων, αγγελίες σπιτιών, γκαρσονιέρες, στούντιο, ειδικές προσφορές, ενοικίαση με τον μήνα, σπίτια, εύρεση σπιτιού, ενοικιασεις σπιτιων, μεσοπρόθεσμη ενοικίαση, ενοικιασεις κατοικιων, σπιτια προς ενοικιαση, καταλυματα, κρατήσεις, ακίνητα, ευκαιρίες, μεσίτες, αγγελίες σπιτιών, γκαρσονιέρα, μονοκατοικία, δυάρι, τριάρι, ελλάδα, ηράκλειο, αθήνα`;


export const Metadata = [
    {
        page: 'home',
        title: 'Furnished Apartments for Rent in Greece | Mid-term Rentals',
        title_el: 'Μεσοπρόθεσμες Ενοικιάσεις Κατοικιών | Επιπλωμένα Σπίτια προς Ενοικίαση',
        description: 'Find fully furnished properties for mid-term rent in Greece. Homes, apartments, studios, flats, houses, hotels, villas and more!',
        description_el: 'Ανακαλύψτε ποιοτικά και πλήρως επιπλωμένα καταλύματα για μεσοπρόθεσμη ενοικίαση από ένα μήνα μέχρι κι ένα χρόνο. Σπίτια, διαμερίσματα, στούντιο, γκαρσονιέρες, κατοικίες, ξενοδοχεία, βίλες κ.α.',
        keywords: 'rentals, furnished apartments, accommodation, home, apartments, houses, apartment, house, properties, rental properties, rental property, rental, homes, housing, mid-term stays, stays, property, month-to-month rentals, monthly rentals, extended stays, mid-term stay, flexible lease, accommodation for a few months, monthly stays, villas, flats, studios, booking, deals, special offers, move-in ready, off-campus housing, digital nomads, homes for digital nomads, apartments for rent, apartments for rent in Greece, Greece, Heraklion, Athens, accommodation in Greece, fully furnished properties, rental properties in greece, properties for rent, apartments for students',
        keywords_el: 'ενοικιάσεις σπιτιών, σπίτια προς ενοικίαση, διαμέρισμα για ενοικίαση, ενοικίαση σπιτιού, διαμέρισμα, διαμονή, διαμερίσματα, ενοικιαζόμενα σπίτια, φοιτητικά σπίτια, ενοικιάσεις κατοικιών, καταλύματα, επιπλωμένα σπίτια, ψάχνω σπίτι, ιδιοκτήτες ακινήτων, αγγελίες σπιτιών, γκαρσονιέρες, στούντιο, ειδικές προσφορές, ενοικίαση με τον μήνα, σπίτια, εύρεση σπιτιού, ενοικιασεις σπιτιων, μεσοπρόθεσμη ενοικίαση, ενοικιασεις κατοικιων, σπιτια προς ενοικιαση, καταλυματα, κρατήσεις, ακίνητα, ευκαιρίες, μεσίτες, αγγελίες σπιτιών, γκαρσονιέρα, μονοκατοικία, δυάρι, τριάρι, ελλάδα, ηράκλειο, αθήνα',
        image: '',
        image_el: ''
    },
    {
        page: 'list_your_property',
        title: 'List Υour Property',
        title_el: 'Καταχωρίστε τo ακίνητό σας',
        description: 'Start earning with Tourmie Stay! List your apartment, home, studio, hotel or villa for free and find easily reliable tenants.',
        description_el: 'Κερδίστε χρήματα με την Tourmie Stay! Καταχωρίστε το διαμέρισμα, σπίτι, στούντιο ή ξενοδοχείο σας δωρεάν και βρείτε εύκολα αξιόπιστους ενοικιαστές.',
        keywords: 'Properties, property, apartments, apartment, homes, rentals, online, homeowners, landlords, property owners, property managers, hosts, real estate owners, property management, property owner, bookings, list, real estate agent, reservations, property advertising, list property, property listing, low season, rent, listing, online, monthly rent, property promotion, tenants, guests',
        keywords_el: 'Ακίνητα, διαμερίσματα, κατοικίες, κατάλυμα, σπίτια, διαχειριστές ακινήτων, διαχειριστής ακινήτων, ιδιοκτήτης ακινήτου, ιδιοκτήτης ακινήτων, ιδιοκτήτες ακινήτων, ιδιοκτήτες, εταιρεία διαχείρισης ακινήτων, καταχώριση ακινήτου, αγγελίες ακινήτων, καταχώριση αγγελίας ακινήτου, online, ενοικιάσεις ακινήτων, ενοικιαστές, εύρεση ενοικιαστώ',
        image: null,
        image_el: null,
    },
    {
        page: 'properties',
        title: 'Properties for rent',
        title_el: 'Σπίτια προς ενοικίαση',
        description: 'Fully furnished apartments, studios, flats, rooms, houses and villas for rent. Find your ideal home and book your stay from 1 month up to 1 year!',
        description_el: 'Ανακάλυψε πλήρως επιπλωμένα διαμερίσματα, σπίτια, στούντιο, γκαρσονιέρες, δωμάτια και βίλες προς ενοικίαση. Βρες το ιδανικό σπίτι και κλείσε τη διαμονή σου από 1 μήνα έως 1 χρόνο! ',
        keywords: 'rentals, furnished apartments, accommodation, home, apartments, houses, apartment, house, properties, rental properties, rental property, rental, homes, housing, mid-term stays, stays, property, month-to-month rentals, monthly rentals, extended stays, mid-term stay, flexible lease, accommodation for a few months, monthly stays, villas, flats, studios, booking, deals, special offers, move-in ready, off-campus housing, digital nomads, homes for digital nomads, apartments for rent, fully furnished properties, properties for rent, apartments for students',
        keywords_el: 'ενοικιάσεις σπιτιών, σπίτια προς ενοικίαση, διαμέρισμα για ενοικίαση, ενοικίαση σπιτιού, διαμέρισμα, διαμονή, διαμερίσματα, ενοικιαζόμενα σπίτια, φοιτητικά σπίτια, ενοικιάσεις κατοικιών, καταλύματα, επιπλωμένα σπίτια, ψάχνω σπίτι, ιδιοκτήτες ακινήτων, αγγελίες σπιτιών, γκαρσονιέρες, στούντιο, ενοικίαση με τον μήνα, σπίτια, εύρεση σπιτιού, ενοικιασεις σπιτιων, μεσοπρόθεσμη ενοικίαση, ενοικιασεις κατοικιων, σπιτια προς ενοικιαση, καταλυματα, κρατήσεις, ακίνητα, ευκαιρίες, μεσίτες, αγγελίες σπιτιών, γκαρσονιέρα, μονοκατοικία, δυάρι, τριάρι',
        image: null,
        image_el: null,
    },
    {
        page: 'property',
        title: null,
        title_el: null,
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'booking',
        title: null,
        title_el: null,
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'bookings',
        title: 'My bookings',
        title_el: 'Οι κρατήσεις μου',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'faq',
        title: 'Frequently Asked Questions',
        title_el: 'Συχνές ερωτήσεις',
        description: 'The most asked questions about Tourmie Stay, the online booking platform for mid-term accommodation.',
        description_el: 'Οι πιο συχνές ερωτήσεις σχετικά με την Tourmie Stay, την πλατφόρμα κρατήσεων για μεσοπρόθεσμες διαμονές καταλυμάτων.',
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'lease_agreement',
        title: 'Lease agreement',
        title_el: 'Συμφωνητικό μίσθωσης',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'profile',
        title: 'My profile',
        title_el: 'Το προφίλ μου',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'reviews',
        title: 'Reviews',
        title_el: 'Αξιολογήσεις',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'favorites',
        title: 'Wishlist',
        title_el: 'Αγαπημένα',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'contact',
        title: 'Contact us',
        title_el: 'Επικοινωνία',
        description: 'Need help? Tourmie Stay’s team is here for you. You can contact us by phone at +30 215 215 4857, via email at stay@tourmie.com or via chat.',
        description_el: 'Χρειάζεστε βοήθεια? Η ομάδα της Tourmie Stay είναι εδώ για εσάς. Μπορείτε να επικοινωνήσετε μαζί μας μέσω τηλεφώνου στο +30 215 215 4857, μέσω email στο stay@tourmie.com ή μέσω chat. ',
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'about',
        title: 'About us',
        title_el: 'Σχετικά με εμάς',
        description: 'Tourmie Stay is an online booking platform for mid-term stays of fully furnished apartments and houses, leased from one month up to one year.',
        description_el: 'Η Tourmie Stay είναι μία ψηφιακή πλατφόρμα μεσοπρόθεσμων ενοικιάσεων διαμερισμάτων και κατοικιών, με διάρκεια διαμονής από ένα μήνα μέχρι κι ένα χρόνο.',
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'agents',
        title: null,
        title_el: null,
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'agent',
        title: null,
        title_el: null,
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'login',
        title: 'Sign in',
        title_el: 'Σύνδεση',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'registration',
        title: 'Create a Tourmie Stay Account',
        title_el: 'Δημιουργία λογαριασμού Tourmie Stay',
        description: 'Create a Tourmie Stay account in minutes! Through Tourmie Stay\'s online booking platform for mid-term stays, you can find fully furnished properties for rent or your next tenants!',
        description_el: 'Δημιουργήστε έναν Tourmie Stay λογαριασμό σε λίγα μόνο λεπτά! Μέσω της πλατφόρμας μεσοπρόθεσμων διαμονών της Tourmie Stay, μπορείτε να βρείτε επιπλωμένα καταλύματα προς ενοικίαση ή τους επόμενους ενοικιαστές σας!',
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'password_reset',
        title: 'Password reset',
        title_el: 'Επαναφορά κωδικού',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'email_verification',
        title: 'Email verification',
        title_el: 'Επαλήθευση διεύθυνσης email',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'maintenance',
        title: 'Maintenance',
        title_el: 'Συντήρηση',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'terms_of_service',
        title: 'Terms of Service',
        title_el: 'Όροι Χρήσης',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: 'privacy_policy',
        title: 'Privacy Policy',
        title_el: 'Πολιτική Απορρήτου',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: '404',
        title: 'Page not found',
        title_el: 'Η σελίδα δεν βρέθηκε',
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    },
    {
        page: '',
        title: null,
        title_el: null,
        description: null,
        description_el: null,
        keywords: null,
        keywords_el: null,
        image: null,
        image_el: null,
    }
];


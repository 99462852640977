<div class="row" *ngIf="!reviewsLoading && reviews?.length > 0">

  <div class="col-12" *ngFor="let review of reviews; let i = index;">

    <a class="card shadow-none rounded-lg text-tourmie-darkblue-2" *ngIf="!isMobile">


    </a>

    <a class="card shadow-none rounded-lg text-tourmie-darkblue-2" *ngIf="isMobile">


    </a>

  </div>

</div>


<div class="row" *ngIf="reviewsLoading">

  <div class="col-12" *ngFor="let item of 5 | enumerate">
    <ngx-skeleton-loader [theme]="{ height:'200px'}"></ngx-skeleton-loader>
  </div>

</div>


<div class="row" *ngIf="!reviewsLoading && reviews?.length === 0">

  <div class="col-12 text-center">
    <h4>

      {{ 'PAGES.CUSTOMER_PROFILE.MENU_REVIEWS.ERROR_NOT_FOUND' | translate }}

    </h4>
  </div>

</div>
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StayEnvironmentSettings } from 'src/app/models';
import { UtilsService } from './utils.service';
import { SeoService } from '../seo/seo-service.service';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    private config: any;

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService,
        private seo: SeoService,
    ) { }

    async loadConfig(): Promise<void> {

        try {

            if (!this.utilsService.isBrowser)
                return Promise.resolve();


            const customDataQueryParams: { domain?: string; } = {}; // { domain: this.utilsService.hostname };


            try {

                if (window?.location?.hostname)
                    customDataQueryParams.domain = window.location.hostname;

            } catch (error) {

            }

            if (!environment.production && this.utilsService.hostname !== 'stay.tourmie.com')
                customDataQueryParams.domain = this.utilsService.hostname;


            let customDataQueryParamsStr = '';


            if (customDataQueryParams?.domain)
                customDataQueryParamsStr = this.utilsService.qs.stringify(customDataQueryParams, { addQueryPrefix: true });

            const data = await this.http.get<StayEnvironmentSettings>(`${environment.params.host}/api/stay/custom-data${customDataQueryParamsStr}`).toPromise();

            await this.utilsService.applyCustomDomainData(data);
            this.seo.initializeMetadata(data);

            return Promise.resolve();

        } catch (error) {

            if (!environment.production)
                console.log(error);

            return Promise.resolve();
        }

    }

    getConfig() {
        return this.config;
    }
}

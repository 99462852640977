<div class="badge badge-pill btn-group btn-group-user p-0" [ngClass]="{'badge-light' : bgChange}" dropdown>

  <!-- dropdown-toggle -->
  <button id="button-menu-dividers" dropdownToggle type="button" class="btn" aria-controls="dropdown-menu-dividers">

    <span [ngClass]="{'text-white' : !bgChange}">
      <i class="far fa-user" [ngClass]="{'mr-1': customer?.customer_id}"></i>
      {{customer?.first_name | titlecase}}
      <span class="fas fa-caret-down" *ngIf="customer"></span>
    </span>


  </button>

  <ul id="dropdown-menu-dividers" *dropdownMenu class="dropdown-menu navbar-user-item-list" role="menu" aria-labelledby="button-menu-dividers">

    <li role="menuitem" *ngIf="!customer?.customer_id">
      <a class="dropdown-item" [routerLink]="[ '/login' ]" [gaEvent]="{ title: 'click_signin_navbar' }">{{'PAGES.LOGIN.BUTTONS.SIGN_IN' | translate }}</a>
    </li>

    <li role="menuitem" *ngIf="customer?.customer_id">
      <a class="dropdown-item" [routerLink]="[ '/profile' ]" [gaEvent]="{ title: 'click_myprofile_navbar' }">{{'GENERIC.BUTTONS.MY_PROFILE' |
        translate}}</a>
    </li>

    <!-- <li role="menuitem" *ngIf="customer?.customer_id">
            <a class="dropdown-item" [routerLink]="[ '/bookings' ]" [gaEvent]="{ title: 'click_mybookings_navbar' }">{{'GENERIC.BUTTONS.MY_BOOKINGS' |
                translate}}</a>
        </li> -->


    <li class="dropdown-divider" *ngIf="customer?.customer_id"></li>

    <li role="menuitem" *ngIf="customer?.customer_id">
      <a class="dropdown-item text-danger" [routerLink]="[ '/logout' ]" [gaEvent]="{ title: 'click_signout_navbar' }">
        {{'GENERIC.BUTTONS.SIGN_OUT' | translate }}
      </a>
    </li>

  </ul>
</div>
<div id="mc_embed_signup" [ngClass]="{'py-4': style === 'footer' && isMobile, 'py-5': style === 'footer' && !isMobile, 'pt-4 pb-5': style !== 'footer'}">

  <form #subscriptionForm="ngForm" autocomplete="off" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank"
    novalidate>

    <div id="mc_embed_signup_scroll">

      <p class="text-white font-size-{{labelFontSize}}" [ngClass]="isMobile ? 'text-left' : 'text-center'">{{'GENERIC.LABELS.SUBSCRIBE_TO_NEWSLETTER' |
        translate}}</p>

      <div class="mc-field-group">

        <div class="row">

          <div [ngClass]="{'col-sm-12 col-md-4 col-lg-4 col-xl-3 offset-lg-2 offset-xl-3': style === 'footer'}">

            <div class="mc-field-group input-group">

              <ul class="d-flex w-100 text-white mb-0" [ngClass]="{'pl-0': isMobile && style ==='footer', 'justify-content-center': !isMobile }">

                <li class="font-weight-normal">{{'GENERIC.LABELS.I_AM_A' | translate }}
                </li>

                <li class="pl-2">
                  <input type="radio" [(ngModel)]="formData.type" value="1" name="group[30869]" id="mce-group[30869]-30869-0">
                  <label for="mce-group[30869]-30869-0" class="px-1 font-weight-normal">{{'GENERIC.LABELS.TENANT_SINGLE' | translate }}</label>
                </li>

                <li class="pl-2">
                  <input type="radio" [(ngModel)]="formData.type" value="2" name="group[30869]" id="mce-group[30869]-30869-1">
                  <label for="mce-group[30869]-30869-1" class="px-1 font-weight-normal">{{'GENERIC.LABELS.LANDLORD_SINGLE' |
                    translate}}</label>
                </li>

              </ul>

            </div>

          </div>

          <div [ngClass]="{'col-sm-12 col-md-6 col-lg-3': style === 'footer'}">

            <div class="input-group input-group-sm justify-content-center">

              <input type="email" value="" placeholder="{{'GENERIC.INPUTS.EMAIL.PLACEHOLDER' | translate }}" name="EMAIL" class="form-control required email"
                id="mce-EMAIL" pattern="[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,50}$" #email="ngModel" [(ngModel)]="formData.email"
                [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}" required>

              <span class="input-group-append">

                <button type="button" role="button" class="btn btn-tourmie-primary" id="buttonNewsletterSubscribe" (click)="submitForm()"
                  [gaEvent]="{ title: 'click_footer_newsletter_subscribe' }" [disabled]="!formData.email || (email.invalid || email.errors)">
                  {{'GENERIC.LABELS.SUBSCRIBE' | translate }}
                </button>

              </span>

            </div>

            <div class="mt-2 font-size-14" *ngIf="mailchimpResponse.msg"
              [ngClass]="{'text-success': mailchimpResponse?.result === 'success', 'text-white': mailchimpResponse?.result === 'error'}"
              [innerHTML]="mailchimpResponse.msg | safe: 'html'"></div>

          </div>


        </div>

      </div>

    </div>

  </form>

</div>
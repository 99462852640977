<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mt-3 mb-2">
        <div class="col-sm-12 text-center">
          <a [routerLink]="[ '/' ]">
            <img [src]="utilsService.customDomainSettings?.logo?.dark" class="logo">
          </a>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->
  <section class="content">

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3">

        <div class="card">

          <div class="card-body">

            <h3 class="text-center mb-3">

              {{'PAGES.EMAIL_VERIFICATION.TITLE' | translate }}

            </h3>

            <div class="row" *ngIf="isLoading">
              <div class="col-12 text-center">
                <i class="fas fa-spinner fa-spin mr-2"></i>{{'PAGES.EMAIL_VERIFICATION.LABELS.LOADING' | translate }}
              </div>
            </div>

            <div class="row" *ngIf="accountVerified">
              <div class="col-12">
                <div class="alert alert-success text-center mb-0">
                  <p class="mb-2">

                    {{'PAGES.EMAIL_VERIFICATION.LABELS.SUCCESS' | translate }}

                  </p>
                  <p class="mb-0">
                    <a [routerLink]="[ '/login']" class="text-tourmie-secondary">

                      {{'PAGES.EMAIL_VERIFICATION.BUTTONS.LOGIN' | translate }}

                    </a>
                  </p>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
    </div>

  </section>
  <!-- /.content -->
</div>
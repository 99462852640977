import { Component, OnDestroy, OnInit, ElementRef, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsService } from '../../../services/google-analytics/google-analytics.service';
import { StorageService } from '../../../services/storage/storage.service';
import { SeoService } from '../../../services/seo/seo-service.service';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { SweetAlertOptions } from 'sweetalert2';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Customer, HostRegistrationData, UserOnboardingData, AccountCategory, DemoAccountRegistrationData, User, Country, RegistrationReferralData, AccountFeature } from '../../../models';
import { UtilsService } from '../../../services/utils/utils.service';



@Component({
  selector: 'app-registration-hosts',
  templateUrl: './registration-hosts.component.html',
  styleUrls: ['./registration-hosts.component.scss']
})
export class RegistrationHostsComponent implements OnInit, OnDestroy {

  public environment = environment;

  public terms: null;

  @ViewChild('addressStreetView', { static: false }) customerAddress: ElementRef; // google place autocomplete

  public isMobile = false;
  private isMobileSubscription: Subscription;

  public termsAgreed;
  public privacyPolicyAgreed;
  public currentStep = 0;
  public euVatValid = true;

  public modalRef: BsModalRef;

  public SearchCountryField = SearchCountryField;
  public TooltipLabel = TooltipLabel;
  public CountryISO = CountryISO;
  public preferredCountries: CountryISO[] = [];
  public selectedCountryISO: CountryISO = null;

  public userMobilePhone;
  public businessPhone;

  public countries: Country[];
  public identity_verification_types = environment.identity_verification.types;

  public isSubmitting = false;
  public isCheckingCustomerTaxID = false;
  public isCheckingCustomerEmail = false;
  public isCheckingCustomerPhone = false;

  public registrationData: HostRegistrationData;

  public queryParams: Params = {};

  public accountCategories: AccountCategory[];


  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private modalService: BsModalService,
    public utilsService: UtilsService,
    private ga: GoogleAnalyticsService,
    private seo: SeoService,
    private storageService: StorageService
  ) {

    this.registrationData = new HostRegistrationData();
    this.registrationData.options.form = 'accommodation_provider';
    delete this.registrationData.options.airbnb;
    delete this.registrationData.options.verification;

    this.countries = this.utilsService.countries;

    this.isMobile = this.utilsService.isMobile;

    this.accountCategories = this.utilsService.lodash.filter(this.utilsService.account_categories, (category) => category.types.includes('accommodation'));

    const { preferredCountries, selectedCountryISO } = this.utilsService.getInternationalPhoneInputConfig();
    this.preferredCountries = preferredCountries;
    this.selectedCountryISO = selectedCountryISO;

  }

  ngOnInit(): void {

    this.seo.updatePageMetadata({ page: 'registration' });

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    // this.ga.sendScreenName('Registration');


    const customer: Customer = this.storageService.getItem('customer');

    // if (customer && customer.id)
    //   this.router.navigate(['/']);

    this.queryParams = this.route.snapshot.queryParams;

    this.initializeAddressAutocomplete();
  }



  ngOnDestroy(): void {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();
  }



  async submitForm(): Promise<void> {

    if (!environment.production)
      console.log(this.registrationData);

    this.isSubmitting = true;

    try {

      if (!this.registrationData.account.email)
        this.registrationData.account.email = this.registrationData.user.email || null;

      if (!this.registrationData.account.phone)
        this.registrationData.account.phone = this.registrationData.user.phone || null;

      if (!this.registrationData.account.address.country)
        this.registrationData.account.address.country = this.registrationData.user.country || null;


      if (this.registrationData.account.type === 'individual') {

        this.registrationData.account.business_name = this.registrationData.account.title;
        this.registrationData.account.tax_id = null;
        this.registrationData.account.tax_authority = null;

      }



      const savedLanguage = this.storageService.getItem('appLanguage');
      this.registrationData.user.language = savedLanguage ? savedLanguage : 'en';



      const onboardingDemoRegistrationData: DemoAccountRegistrationData = {
        user: {
          first_name: this.registrationData.user.first_name,
          last_name: this.registrationData.user.last_name,
          email: this.registrationData.user.email,
          password: this.registrationData.user.password,
          phone: this.registrationData.user.phone,
          country: this.registrationData.user.country,
        },
        account: {
          type: this.registrationData.account.type,
          business_name: this.registrationData.account.business_name,
          title: this.registrationData.account.title,
          email: this.registrationData.account.email,
          phone: this.registrationData.account.phone,
          website: this.registrationData.account.website,
          tax_id: this.registrationData.account.tax_id,
          tax_authority: this.registrationData.account.tax_authority,
          representative: this.registrationData.account.representative,
          contact_person: this.registrationData.account.contact_person,
          accommodation_category: this.registrationData.account.accommodation_category,
          subscription_properties_max: this.registrationData.account.subscription_properties_max || 100,
          provider_type: ['accommodation'],
          is_accommodation_provider: true,
          is_tourmie_stay_landlord: true,
          address: this.registrationData.account.address,
        },
        options: this.registrationData.options || null,
      };


      // Handle referral/subreferral
      try {

        const registrationReferralData = await this.initializeRegistrationReferralData(onboardingDemoRegistrationData);

        if (registrationReferralData) {

          if (!onboardingDemoRegistrationData.options)
            onboardingDemoRegistrationData.options = {};

          onboardingDemoRegistrationData.options.referral = registrationReferralData?.referral || null;
          onboardingDemoRegistrationData.options.subreferral = registrationReferralData?.subreferral || null;

        }

      } catch (error) {

      }


      // 3. Create Tourmie User & Account
      const newOnboardingUser = await this.http.post<UserOnboardingData>(`${environment.params.host}/api/onboarding/demo/init`, onboardingDemoRegistrationData).toPromise(); // contains User & its new account

      if (!environment.production)
        console.log({
          registrationData: this.registrationData,
          customerData: newOnboardingUser
        });


      this.ga.sendEvent('initial_registration');

      const onboardingDemoSetupResponse = await this.http.post<{ user: User; onboarding_data: UserOnboardingData }>(`${environment.params.host}/api/onboarding/demo/setup/${newOnboardingUser.uuid}`, {
        demo_registration_data: onboardingDemoRegistrationData
      }).toPromise();


      if (!environment.production)
        console.log(onboardingDemoSetupResponse);


      this.ga.sendEvent('new_host_acccount_created');


      if (onboardingDemoSetupResponse?.user?.user_id) {

        if (onboardingDemoSetupResponse?.user?.account?.account_id)
          await this.setupAccount(onboardingDemoSetupResponse.user);



        const magicLinkSendResponse = await this.http.get<{ user: User; onboarding_data: UserOnboardingData }>(`${environment.params.host}/api/auth/login/token/generate`, {
          params: { email: onboardingDemoSetupResponse.user.email }
        }).toPromise();



        try {

          const updateViewedOnboardingAttributes = await this.http.post(`${environment.params.host}/api/utils/update-viewed-onboarding-status`, onboardingDemoSetupResponse.user).toPromise();

        } catch (error) {

        }


        this.isSubmitting = false;

        await this.utilsService.showAlert({
          title: await this.translate.get('GENERIC.LABELS.SUCCESS_EXCLAMARK').toPromise(),
          html: await this.translate.get('PAGES.MAGIC_LINK_SENT_PAGE.LINK_SENT_LABEL', { email_address: onboardingDemoSetupResponse.user.email }).toPromise(),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_CREATED.CONFIRM_BUTTON').toPromise(),
        });

        this.router.navigate(['/']);

      }

      this.isSubmitting = false;

    } catch (error) {

      console.log(error);

      this.isSubmitting = false;

    }

  }



  onFirstnameChange(): void {
    this.registrationData.account.contact_person = `${this.registrationData.user.first_name || ''} ${this.registrationData.user.last_name || ''}`;
  }



  onLastnameChange(): void {
    this.registrationData.account.contact_person = `${this.registrationData.user.first_name || ''} ${this.registrationData.user.last_name || ''}`;
  }



  onPasswordChange(): void {

  }



  onAccountCategoryChange(): void {

    if (this.registrationData.account.category === 'individual_host') {
      this.registrationData.account.type = 'individual';
      this.registrationData.account.accommodation_category = 'apartment';
    }
    else {
      this.registrationData.account.type = 'business';

      if (this.registrationData.account.category === 'professional_host')
        this.registrationData.account.accommodation_category = 'apartment';

      if (this.registrationData.account.category === 'hotel')
        this.registrationData.account.accommodation_category = 'hotel';

    }

  }



  onPropertiesNumberChange(): void {

    if (!this.registrationData?.account)
      return;

    if (!this.registrationData?.account?.subscription_properties_max || this.registrationData?.account?.subscription_properties_max <= 0)
      this.registrationData.account.subscription_properties_max = null;

  }



  onAddressCountryChange(): void {
    this.registrationData.account.address.country = this.registrationData?.user?.country || null;
  }



  onBusinessCheckboxChange(event: any): void {
    if (event.target.checked)
      this.registrationData.account.type = 'business';
    else {
      this.registrationData.account.type = 'individual';
      this.registrationData.account.email = null;
      this.registrationData.account.business_name = null;
      this.registrationData.account.phone = null;
      this.registrationData.account.title = null;
      // this.registrationData.tax_authority = null;
      // this.registrationData.account.tax_id = null;
      this.businessPhone = null;
    }

  }



  updateUserMobileNumber() {
    if (this.userMobilePhone?.internationalNumber)
      this.registrationData.user.phone = this.userMobilePhone.internationalNumber;
    else
      this.registrationData.user.phone = null;
  }



  updateBusinessMobileNumber() {
    if (this.businessPhone?.internationalNumber)
      this.registrationData.account.phone = this.businessPhone.internationalNumber;
    else
      this.registrationData.account.phone = null;
  }



  onVatNumberChange(): void {
    if (this.registrationData.account.tax_id)
      this.registrationData.account.tax_id = this.registrationData.account.tax_id.replace(/\s/g, '');
  }



  async vatCheck(): Promise<any> {
    try {

      const vatResponse = this.utilsService.checkVatNumber(this.registrationData.account.address.country, this.registrationData.account.tax_id);
      if (vatResponse) // VAT number is for EU Business

        if (!vatResponse.isValid && vatResponse.country.vat_prefix && vatResponse.country.name) {

          this.utilsService.swal.fire(
            (await this.translate.get('PAGES.REGISTRATION.ALERTS.INVALID_VAT_NUMBER_FORMAT.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
            (await this.translate.get('PAGES.REGISTRATION.ALERTS.INVALID_VAT_NUMBER_FORMAT.HTML', {
              countryName: vatResponse.country.name,
              vatPrefixForCountry: vatResponse.country.vat_prefix
            }).toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
            'warning');

          this.isSubmitting = false;

          return Promise.reject();

        }

      return Promise.resolve();

    } catch (error) {
      return Promise.reject({ message: (await this.translate.get('PAGES.REGISTRATION.ALERTS.VAT_VERIFICATION_FAILED.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title) });
    }
  }



  async checkIfAccountExistByTaxID(): Promise<any> {
    try {

      const userExist = await this.utilsService.checkIfAccountExistsByTaxID(this.registrationData.account.tax_id);

      if (userExist) {

        this.utilsService.swal.fire(
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_ALREADY_EXIST_WITH_THIS_TAX_ID.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_ALREADY_EXIST_WITH_THIS_TAX_ID.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          'error'
        );

        return Promise.reject();

      }

    } catch (error) {
      return Promise.reject();
    }
  }



  async checkIfAccountExistByPhone(): Promise<any> {

    try {

      const userExist = await this.utilsService.checkIfAccountExistsByPhone(this.registrationData.account.phone);

      if (userExist) {
        this.utilsService.swal.fire(
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_ALREADY_EXIST_WITH_THIS_PHONE.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_ALREADY_EXIST_WITH_THIS_PHONE.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          'error'
        );

        return Promise.reject();

      }

    } catch (error) {
      return Promise.reject();
    }
  }



  async nextStep(): Promise<void> {

    this.isCheckingCustomerEmail = true;

    try {

      this.isCheckingCustomerEmail = false;

      this.currentStep += 1;

      if (this.utilsService.isBrowser)
        window.scrollTo(0, 0);

    } catch (error) {

      this.isCheckingCustomerEmail = false;
      console.log(error);

    }

  }



  previousStep(): void {
    this.currentStep -= 1;
  }



  async initializeAddressAutocomplete(): Promise<void> {

    if (this.utilsService.isBrowser) {

      await this.utilsService.delay(2000);

      if (!this.customerAddress?.nativeElement)
        return Promise.resolve();

      const returnLocationAutocomplete = new google.maps.places.Autocomplete(this.customerAddress.nativeElement, {}); // types: ['establishment']  // 'establishment' / 'address' / 'geocode'

      google.maps.event.addListener(returnLocationAutocomplete, 'place_changed', async () => {

        const place = returnLocationAutocomplete.getPlace();
        const addressDetails = await this.utilsService.extractGoogleAddressDetails(place, true); // (city, state, country)

        this.registrationData.account.address.city = (addressDetails?.city) ? addressDetails.city : null;
        this.registrationData.account.address.is_city = (addressDetails?.is_city) ? addressDetails.is_city : false;
        this.registrationData.account.address.state = (addressDetails?.state) ? addressDetails.state : null;
        this.registrationData.account.address.country = (addressDetails?.country) ? addressDetails.country : null;
        this.registrationData.account.address.street = (addressDetails?.street) ? addressDetails.street : null;
        this.registrationData.account.address.street_formatted = (addressDetails.street_formatted) ? addressDetails.street_formatted : null;
        this.registrationData.account.address.postal_code = (addressDetails?.postal_code) ? addressDetails.postal_code : null;
        this.registrationData.account.address.latitude = (place?.geometry?.location?.lat() && place.geometry.location.lat()) ? place.geometry.location.lat() : null;
        this.registrationData.account.address.longitude = (place?.geometry?.location?.lng() && place.geometry.location.lng()) ? place.geometry.location.lng() : null;
        this.registrationData.account.address.google_place_id = (addressDetails.google_place_id) ? addressDetails.google_place_id : null;
        this.registrationData.account.address.utc_offset = (addressDetails.utc_offset) ? addressDetails.utc_offset : null;
        this.registrationData.account.address.url = (addressDetails.url) ? addressDetails.url : null;
        this.registrationData.account.address.types = (addressDetails.types) ? addressDetails.types : null;

        this.customerAddress.nativeElement.value = (addressDetails?.street) ? addressDetails.street : null;


        this.customerAddress.nativeElement.blur();
        this.customerAddress.nativeElement.focus();
        this.customerAddress.nativeElement.blur();

        this.cdRef.detectChanges();
        return Promise.resolve();

      });

    }

  }



  async setupAccount(user: User): Promise<void> {

    try {

      user = await this.initializeAccountSetupData(user);

      const response = await this.http.put(`${environment.params.host}/api/account-setup-wizard`, { account: user.account }).toPromise();

      return Promise.resolve();

    } catch (error) {

      if (!environment.production)
        console.log(error);

      // this.isSettingupAccount = false;

      return Promise.resolve();

    }


  }



  async initializeAccountSetupData(user: User): Promise<User> {

    try {


      if (!user?.account?.account_id)
        return Promise.resolve(null);

      user.account.features = {
        guest_guide: new AccountFeature({ name: 'guest_guide', enabled: false }),
        partner_finder: new AccountFeature({ name: 'partner_finder', enabled: false }),
        services_booking_engine: new AccountFeature({ name: 'services_booking_engine', enabled: false }),
        online_checkins: new AccountFeature({ name: 'online_checkins', enabled: false }),
        guest_requests: new AccountFeature({ name: 'guest_requests', enabled: false }),
        chat: new AccountFeature({ name: 'chat', enabled: false }),
        restaurant_reservations: new AccountFeature({ name: 'restaurant_reservations', enabled: false }),
        automated_messages: new AccountFeature({ name: 'automated_messages', enabled: false }),
        system_automated_messages: new AccountFeature({ name: 'system_automated_messages', enabled: false }),
        unified_inbox: new AccountFeature({ name: 'unified_inbox', enabled: false }),
        domain_name: new AccountFeature({ name: 'domain_name', enabled: false }),
        custom_email: new AccountFeature({ name: 'custom_email', enabled: false }),
        conferences: new AccountFeature({ name: 'conferences', enabled: false }),
        chatbot: new AccountFeature({ name: 'chatbot', enabled: false }),
      };


      return Promise.resolve(user);

    } catch (error) {

      if (!environment.production)
        console.log(error);

      return Promise.resolve(user);

    }

  }



  async initializeRegistrationReferralData(data?: DemoAccountRegistrationData): Promise<RegistrationReferralData> {

    try {

      if (
        this.queryParams.referral_id
        || this.storageService.getItem('account_referral_id')
        || data?.options?.referral?.id
        || this.queryParams.referral_name
        || this.storageService.getItem('account_referral_name')
        || data?.options?.referral?.name
        || this.queryParams.subreferral_id
        || this.storageService.getItem('account_subreferral_id')
        || data?.options?.subreferral?.id
        || this.queryParams.subreferral_name
        || this.storageService.getItem('account_subreferral_name')
        || data?.options?.subreferral?.name
      ) {

        const result: RegistrationReferralData = {
          referral: {
            id: this.queryParams.referral_id || this.storageService.getItem('account_referral_id') || data?.options?.referral?.id || null,
            name: this.queryParams.referral_name || this.storageService.getItem('account_referral_name') || data?.options?.referral?.name || null,
          },
          subreferral: {
            id: this.queryParams.subreferral_id || this.storageService.getItem('account_subreferral_id') || data?.options?.subreferral?.id || null,
            name: this.queryParams.subreferral_name || this.storageService.getItem('account_subreferral_name') || data?.options?.subreferral?.name || null,
          }
        };

        return Promise.resolve(result);

      }

      else
        return Promise.resolve(null);

    } catch (error) {
      return Promise.resolve(null);
    }

  }



  // Terms of Service html generation
  async getTermsOfService(template: TemplateRef<any>): Promise<void> {
    try {

      if (
        !this.registrationData.account.title
        || !this.registrationData.account.address.country
        || (
          this.registrationData.account.type === 'business' && (
            !this.registrationData.account.phone
            || !this.registrationData.account.email
            || !this.registrationData.account.business_name
            || !this.registrationData.account.tax_id
          )
        )
      ) {

        this.utilsService.swal.fire(
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.REQUIRED_FIELDS_MISSING.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.REQUIRED_FIELDS_MISSING.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          'info'
        );

        return Promise.resolve();

      }

      const termsResponse = await this.http.post<any>(`${environment.params.host}/api/stay/utils/get-terms-landlord?source=registration`, this.registrationData).toPromise();

      this.terms = termsResponse.terms;
      if (termsResponse.terms && this.utilsService.isBrowser) {

        const options: ModalOptions = {
          class: 'modal-lg second',
          backdrop: 'static'
        };

        this.modalRef = this.modalService.show(template, options);

      }

    } catch (error) {

      if (!environment.production)
        console.log(error);

    }
  }

}

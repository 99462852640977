<div class="content-wrapper" [hidden]="!utilsService.showContent">

  <div class="container">

    <div class="row mb-5" [ngClass]="{'px-2': isMobile}">

      <div class="col-sm-12 col-md-4">

        <div class="card bg-white shadow-none rounded-lg">

          <div class="card-body" *ngIf="customer">

            <div class="row">

              <div class="col-3 text-center">

                <div class="user-profile-pic">

                  <img [src]="customer.profile_pic_url" onerror="this.src='assets/img/customer-profile/customer-profile-placeholder-rounded.png';"
                    class="rounded-circle user-profile-pic-image">


                  <div class="user-profile-pic-content">
                    <!-- Upload icon (camera) -->
                    <span class="user-profile-pic-icon" (click)="onProfilePictureUploadIconClick();"
                      *ngIf="!customer.profile_pic_url && !isUpdatingProfilePicture" tooltip="Update profile picture" container="body" placement="bottom">
                      <i class="fas fa-camera"></i>
                    </span>

                    <!-- Delete icon -->
                    <span class="user-profile-pic-icon text-danger" (click)="onProfilePictureDeleteIconClick();"
                      *ngIf="customer.profile_pic_url && !isUpdatingProfilePicture" tooltip="Remove profile picture" container="body" placement="bottom">
                      <i class="far fa-trash-alt"></i>
                    </span>

                  </div>

                </div>

                <input type="file" class="custom-file-input d-none" id="profilePicture" name="profilePicture" #profilePicture="ngModel"
                  [(ngModel)]="profilePictureData" (change)="onProfilePictureChange($event);" accept=".jpeg,.jpg,.png" aria-describedby="profilePictureHelp">

              </div>

              <div class="col-9 my-auto">

                <h5 class="mb-1 text-truncate">{{customer?.fullname | titlecase}}</h5>
                <p class="mb-0 text-muted font-size-13 text-truncate">{{customer.email}}</p>

              </div>

            </div>

            <!-- spinner icon for picture update/delete -->
            <div class="row mt-2" *ngIf="isUpdatingProfilePicture">

              <div class="col-4 offset-4 text-center">

                <span>
                  <i class="fas fa-sync-alt fa-spin text-muted"></i>
                </span>

              </div>

            </div>

          </div>

        </div>

        <div class="card bg-white shadow-none rounded-lg">

          <div class="card-body">
            <div class="card-header border-0">

              <h5>{{'PAGES.CUSTOMER_PROFILE.LABELS.TITLE' | translate }}</h5>
            </div>

            <ul class="list-group list-group-flush">

              <li class="list-group-item profile-nav-item border-0 mb-1" routerLinkActive="active bg-tourmie-primary-90">



                <a class="text-tourmie-darkblue-2 w-100 d-block" [routerLink]="['/profile']">
                  <i class="far fa-address-card mr-2"></i>
                  {{'PAGES.CUSTOMER_PROFILE.LABELS.MENU_ITEM_1' | translate }}
                </a>

              </li>

              <li class="list-group-item profile-nav-item border-0 mb-1" routerLinkActive="active bg-tourmie-primary-light-5">



                <a class="text-tourmie-darkblue-2 w-100 d-block" [routerLink]="['/bookings']">
                  <i class="fas fa-calendar-alt mr-2"></i>
                  {{'PAGES.CUSTOMER_PROFILE.LABELS.MENU_ITEM_2' | translate }}
                </a>

              </li>

              <li class="list-group-item profile-nav-item border-0 mb-1" routerLinkActive="active bg-tourmie-primary-light-5">



                <a class="text-tourmie-darkblue-2 w-100 d-block" [routerLink]="['/favorites']">
                  <i class="far fa-heart mr-2"></i>
                  {{'PAGES.CUSTOMER_PROFILE.LABELS.MENU_ITEM_3' | translate }}
                </a>

              </li>

              <li class="list-group-item profile-nav-item border-0 mb-1" routerLinkActive="active bg-tourmie-primary-light-5">



                <a class="text-tourmie-darkblue-2 w-100 d-block" [routerLink]="['/reviews']">
                  <i class="far fa-star mr-2"></i>
                  {{'PAGES.CUSTOMER_PROFILE.LABELS.MENU_ITEM_4' | translate }}
                </a>

              </li>

            </ul>

          </div>

        </div>

        <div class="card bg-white shadow-none rounded-lg" [ngClass]="{'d-none': isMobile}">

          <div class="card-body">

            <div class="card-header border-0">

              <h5>{{'PAGES.CUSTOMER_PROFILE.LABELS.HELP' | translate }}</h5>

            </div>

            <ul class="list-group list-group-flush">

              <li class="list-group-item border-0 mb-1">

                <a class="text-tourmie-darkblue-2 w-100 d-block" [routerLink]="['/faq']">
                  <i class="far fa-question-circle mr-2"></i>
                  {{'PAGES.CUSTOMER_PROFILE.LABELS.FAQ' | translate }}
                </a>

              </li>

              <li class="list-group-item border-0 mb-1">

                <a class="text-tourmie-darkblue-2 w-100 d-block" [routerLink]="['/contact']">
                  <i class="far fa-life-ring mr-2"></i>
                  {{'PAGES.CUSTOMER_PROFILE.LABELS.SUPPORT' | translate }}
                </a>

              </li>

            </ul>
          </div>
        </div>

      </div>

      <div class="col-sm-12 col-md-8" [ngClass]="{'pl-3': !isMobile}">

        <div class="row">


          <div class="col-12" *ngIf="state === 'information'">

            <app-customer-information [customer]="customer"></app-customer-information>

          </div>


          <div class="col-12" *ngIf="state === 'bookings'">

            <app-customer-bookings [customer]="customer"></app-customer-bookings>

          </div>


          <div class="col-12" *ngIf="state === 'favorites'">

            <app-customer-favourites [customer]="customer"></app-customer-favourites>

          </div>


          <div class="col-12" *ngIf="state === 'reviews'">

            <app-customer-reviews [customer]="customer"></app-customer-reviews>

          </div>

        </div>


      </div>

    </div>

  </div>

</div>
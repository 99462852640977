import { GraphqlService } from '../graphql/graphql.service';
import { Customer, StayEnvironmentSettings, Address, VatCheckResponse, Language, Country } from '../../models';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable, HostListener, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';


import * as lodash from 'lodash';
import * as moment from 'moment-timezone';
import * as qs from 'qs';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { EventManager } from '@angular/platform-browser';

import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { CountryISO } from 'ngx-intl-tel-input';


@Injectable({ providedIn: 'root' })

export class UtilsService implements OnDestroy {

    public allowedCountriesForMobileNumbers: string[];

    public account_categories = environment.accounts.categories;

    public propertySubcategoriesOptions = environment.properties.subcategories;
    public propertyAmenitiesGroupOptions = environment.properties.amenities.categories;
    public propertyAmenitiesOptions = environment.properties.amenities.items;
    public propertyExtraCostsOptions = environment.properties.extra_costs.items;
    public propertyGeneralInfoItems = environment.properties.accommodation_general_info_items;
    public propertyBedTypes = environment.properties.bed_types;
    public customerIdentityTypeOptions = environment.identity_verification.types;
    public bookingStatusOptions = environment.bookings.statuses;
    public bookingPaymentStatusOptions = environment.bookings.payment_statuses;
    public identityImageTypeOptions = environment.identity_verification.image_types;

    public customDomainSettings: StayEnvironmentSettings = new StayEnvironmentSettings();
    public environmentSettingsSubscription: Subscription;
    public showContent = false;


    private isMobileSubject = new Subject<any>();
    public isMobile = false;

    private isMobileAndNotTabletSubject = new Subject<any>();
    public isMobileAndNotTablet = false;

    public isBrowser = false;

    public hostname = 'stay.tourmie.com';
    public hostIsTourmie = true;

    public lodash = lodash;
    public swal = Swal;
    public qs = qs;
    public moment = moment;

    public currentLanguage: string;
    public langSubscription: Subscription;
    public countries: Country[] = environment.countries;
    public languages: Language[] = environment.languages;
    public timezones = environment.timezones;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private http: HttpClient,
        private router: Router,
        private eventManager: EventManager,
        public toast: ToastrService,
        private modalService: BsModalService,
        private storageService: StorageService,
        private graphqlService: GraphqlService,
        public spinner: NgxSpinnerService,
        public pubSub: NgxPubSubService
    ) {

        this.isBrowser = isPlatformBrowser(this.platformId);

        if (isPlatformBrowser(this.platformId)) {

            this.hostname = window?.location?.host || window?.location?.hostname || null;

            // this.hostname = 'booking.nomad365.org'; // NOTE: enable me to simulate this domain

            this.isMobile = window.innerWidth <= 991.98;
            this.isMobileAndNotTablet = window.innerWidth <= 767.98;

        }

        this.allowedCountriesForMobileNumbers = this.getAllowedCountriesForMobileNumbers();

        this.eventManager.addGlobalEventListener('window', 'resize', this.windowIsMobileObs.bind(this));

        this.langSubscription = this.pubSub.subscribe('changeLanguage', (language) => {

            this.currentLanguage = language;

            try {
                (window as any).tourmie_stay_variables_ng.current_language = language;
            } catch (error) {

            }

        });


        const currentLanguage = this.storageService.getItem('appLanguage');
        this.currentLanguage = currentLanguage || 'en';



        try { // set currentLanguage in window

            if (window) {
                if (!(window as any).tourmie_stay_variables_ng)
                    (window as any).tourmie_stay_variables_ng = {
                        current_language: this.currentLanguage
                    };
                else
                    (window as any).tourmie_stay_variables_ng.current_language = this.currentLanguage;
            }

        } catch (error) {

        }

    }

    ngOnDestroy(): void {
        if (this.langSubscription)
            this.langSubscription.unsubscribe();

    }



    public delay(milliseconds: number) {

        return new Promise(resolve => {
            if (isPlatformBrowser(this.platformId))
                setTimeout(resolve, milliseconds);
        });
    }



    public showToast(message: string, title?: string, type?: 'success' | 'warning' | 'error' | 'info', timeout?: number): void {

        const toastOptions: any = {
            closeButton: false,
            positionClass: 'toast-bottom-right'
        };

        if (timeout) {
            toastOptions.timeOut = timeout;
            toastOptions.disableTimeOut = false;
        }
        // else
        //     toastOptions.disableTimeOut = true;

        switch (type) {
            case 'success':
                this.toast.success(
                    message,
                    (title) ? title : 'Notification',
                    toastOptions);
                break;

            case 'warning':
                this.toast.warning(
                    message,
                    (title) ? title : 'Notification',
                    toastOptions);
                break;

            case 'error':
                this.toast.error(
                    message,
                    (title) ? title : 'Notification',
                    toastOptions);
                break;

            case 'info':
                this.toast.info(
                    message,
                    (title) ? title : 'Notification',
                    toastOptions);
                break;

            default:
                this.toast.info(
                    message,
                    (title) ? title : 'Notification',
                    toastOptions);
                break;
        }


    }



    async logout(): Promise<any> {

        try {

            const logoutResponse: any = await this.http.post(`${environment.params.host}/api/stay/auth/logout`, {}).toPromise();

            this.storageService.removeItem('customer');
            return Promise.resolve();

        } catch (error) {

            if (!environment.production)
                console.log(error);

            return Promise.resolve();

        }
    }



    textContainsURL(text: string): boolean {

        const urlRegex = new RegExp(/(http|https?:\/\/)?[\w\-~]+(\.[\^a-zA-Z\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/ig); // all characters except digits

        return urlRegex.test(text);
    }



    /**
     * Returns number as string.
     * For example 1 -> "First" or "1st", 2 -> "Second" or "2nd", etc..
     */
    numberToOrdinal(num: number, toOrdinal?: boolean, article?: 'm' | 'f' | 'n'): string {


        const special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelvth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
        const deca = ['twent', 'thirt', 'fourt', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

        let stringified = '';

        num = parseInt(num.toString(), 10); // parse to Int if is e.g. float


        if (this.currentLanguage === 'el')
            if (!article)
                return `${num}ος`;
            else if (article === 'm') // male
                return `${num}ος`;
            else if (article === 'f') // female
                return `${num}η`;
            else // neutral
                return `${num}ο`;

        else {

            if (num < 20)
                stringified = special[num];

            else if (num % 10 === 0)
                stringified = deca[Math.floor(num / 10) - 2] + 'ieth';

            else
                stringified = deca[Math.floor(num / 10) - 2] + 'y-' + special[num % 10];

            if (!stringified || stringified === '')
                return 'N/A';

            if (!toOrdinal)
                return stringified.charAt(0).toUpperCase() + stringified.slice(1); // capitalize first letter, e.g. returns "First"

            return `${num}${stringified.slice(-2)}`;
        }

    }



    async showAlert(options: SweetAlertOptions): Promise<SweetAlertResult> {
        const result = await this.swal.fire(options);
        return Promise.resolve(result);
    }



    windowIsMobileObs() {

        if (isPlatformBrowser(this.platformId)) {

            this.isMobile = window.innerWidth <= 991.98;
            return this.isMobileSubject.next(this.isMobile);

        }

    }



    windowIsMobile(): Observable<any> {
        return this.isMobileSubject.asObservable();
    }



    windowIsMobileAndNotTabletObs() {

        if (isPlatformBrowser(this.platformId)) {

            this.isMobileAndNotTablet = window.innerWidth <= 767.98;
            return this.isMobileAndNotTabletSubject.next(this.isMobileAndNotTablet);

        }

    }



    windowIsMobileAndNotTablet(): Observable<any> {
        return this.isMobileAndNotTabletSubject.asObservable();
    }



    getAllowedCountriesForMobileNumbers(): string[] {

        const countryCodes: string[] = [];
        for (const country of environment.countries)
            countryCodes.push(country.code.toLowerCase());

        return countryCodes;
    }



    checkVatNumber(countryCode: string, vatNumber: string): VatCheckResponse {

        const selectedCountry = lodash.find(environment.countries, (country) => country.code === countryCode);

        if (!selectedCountry.vat_prefix)
            return null;



        if (vatNumber.startsWith(selectedCountry.vat_prefix))
            return { isValid: true, country: selectedCountry };
        else
            return { isValid: false, country: selectedCountry };

    }



    async checkIfAccountExistsByTaxID(accountTaxID: string): Promise<boolean> {
        try {
            const response: any = await this.http.post(`${environment.params.host}/api/stay/check-account-tax-id-exist`, { tax_id: accountTaxID }).toPromise();
            if (response?.exists)
                return Promise.resolve(true);

            return Promise.resolve(false);

        } catch (error) {
            return Promise.reject(false);
        }
    }



    async checkIfAccountExistsByEmail(accountEmail: string): Promise<boolean> {
        try {
            const response: any = await this.http.post(`${environment.params.host}/api/stay/check-email-exist`, { email: accountEmail }).toPromise();
            if (response?.exists)
                return Promise.resolve(true);

            return Promise.resolve(false);

        } catch (error) {
            return Promise.reject(false);
        }
    }



    async checkIfAccountExistsByPhone(accountPhone: string): Promise<boolean> {
        try {
            const response: any = await this.http.post(`${environment.params.host}/api/stay/check-phone-exist`, { phone: accountPhone }).toPromise();
            if (response?.exists)
                return Promise.resolve(true);

            return Promise.resolve(false);

        } catch (error) {
            return Promise.reject(false);
        }
    }



    getCountryNameByVatPrefix(vatNumberCountry: string): string {

        const country = lodash.find(environment.countries, (c) => c.vat_prefix === vatNumberCountry);

        if (!country)
            return '';

        return country.name;
    }



    getLanguageNameByCode(languageCode: string): string {
        const language = lodash.find(environment.params.appLanguages, (lang) => lang.code === languageCode);

        if (!language)
            return '';

        return language.language;
    }



    getCountryByTimezone(): Country {

        try {

            const timezone = moment.tz.guess();
            let country: Country = null;

            if (timezone) {

                const foundTimezone = this.lodash.find(this.timezones, (zone) => zone.timezone === timezone);

                if (foundTimezone?.country_code)
                    country = this.lodash.find(this.countries, (c) => c.code === foundTimezone.country_code);

            }

            return country?.code ? country : null;

        } catch (error) {
            return null;
        }

    }



    getInternationalPhoneInputConfig(): { preferredCountries: CountryISO[], selectedCountryISO: CountryISO } {

        let preferredCountries: CountryISO[] = [];
        let selectedCountryISO: CountryISO = null;

        const countryFromTimezone = this.getCountryByTimezone();

        if (countryFromTimezone?.code)

            for (const value in CountryISO)

                if ((CountryISO[value] as string).toUpperCase() === countryFromTimezone.code) {

                    preferredCountries = [(countryFromTimezone.code as any).toLowerCase()];

                    if (preferredCountries.length > 0)
                        selectedCountryISO = preferredCountries[0];

                    break;

                }

        if (preferredCountries?.length === 0 || !selectedCountryISO) {
            preferredCountries = [CountryISO.Greece];
            selectedCountryISO = CountryISO.Greece;
        }


        return { preferredCountries: preferredCountries, selectedCountryISO: selectedCountryISO };

    }



    getPropertyTypeLabel(propertyType: string): string {
        const propertyTypeObj = lodash.find(this.propertySubcategoriesOptions, (type) => type.value === propertyType);


        if (this.currentLanguage === 'el' && propertyTypeObj?.label_el)
            return propertyTypeObj?.label_el;


        return propertyTypeObj?.label || 'n/a';
    }



    getPropertyAmenityGroupLabel(groupValue: string): string {
        const propertyAmenityGroupObj = lodash.find(this.propertyAmenitiesGroupOptions, (group) => group.value === groupValue);

        if (this.currentLanguage === 'el' && propertyAmenityGroupObj?.label_el)
            return propertyAmenityGroupObj?.label_el;

        return propertyAmenityGroupObj?.label || 'n/a';
    }



    getPropertyAmenityLabel(amenityValue: string): string {
        const propertyAmenityObj = lodash.find(this.propertyAmenitiesOptions, (amenity) => amenity.value === amenityValue);


        if (this.currentLanguage === 'el' && propertyAmenityObj?.label_el)
            return propertyAmenityObj?.label_el;

        return propertyAmenityObj?.label || 'n/a';
    }



    getPropertyExtraCostLabel(extraCostType: string, serviceType: 'accommodation' | 'rent_car' | 'transfer' | 'experience' | 'food'): string {
        const propertyExtraCostObj = lodash.find(this.propertyExtraCostsOptions, (extraCostObj) => extraCostObj.value === extraCostType && extraCostObj.service_type === serviceType);

        if (this.currentLanguage === 'el' && propertyExtraCostObj?.label_el)
            return propertyExtraCostObj?.label_el;


        return propertyExtraCostObj?.label || 'n/a';
    }



    getIdentityImageTypeLabel(identityImageTypeValue: string): string {
        const identityImageTypeOptions = lodash.find(this.identityImageTypeOptions, (idImgObj) => idImgObj.value === identityImageTypeValue);

        if (this.currentLanguage === 'el' && identityImageTypeOptions?.label_el)
            return identityImageTypeOptions?.label_el;

        return identityImageTypeOptions?.label || 'n/a';
    }



    getCustomerIdentityTypeLabel(identityTypeValue: string): string {
        const identityTypeObj = lodash.find(this.customerIdentityTypeOptions, (idObj) => idObj.value === identityTypeValue);

        if (this.currentLanguage === 'el' && identityTypeObj?.label_el)
            return identityTypeObj?.label_el;

        return identityTypeObj?.label || 'n/a';
    }



    getBookingStatusLabel(bookingStatusLabel: string): string {

        const bookingStatusesObj = lodash.find(this.bookingStatusOptions, (Obj) => Obj.value === bookingStatusLabel);

        if (this.currentLanguage === 'el' && bookingStatusesObj?.label_el)
            return bookingStatusesObj?.label_el;

        return bookingStatusesObj?.label || 'n/a';
    }



    getBookingPaymentStatusLabel(bookingPaymentStatusValue: string): string {

        const bookingStatusPaymentObj = lodash.find(this.bookingPaymentStatusOptions, (Obj) => Obj.value === bookingPaymentStatusValue);

        if (this.currentLanguage === 'el' && bookingStatusPaymentObj?.value && bookingStatusPaymentObj?.label_el)
            return bookingStatusPaymentObj?.label_el;

        return bookingStatusPaymentObj?.label || 'n/a';

    }



    getBedLabel(bedValue: string): string {

        const bedTypeObj = lodash.find(this.propertyBedTypes, (obj) => obj.value === bedValue);

        if (this.currentLanguage === 'el' && bedTypeObj?.value && bedTypeObj?.label_el)
            return bedTypeObj?.label_el;

        return bedTypeObj?.label || 'n/a';

    }



    multilingualDropdownSearchFunction(term: string, item: any): boolean {

        if (typeof item === 'object' && !lodash.isEmpty(item)) {

            term = term.toLowerCase();

            // Creating and array of space saperated term and removinf the empty values using filter
            const splitTerm = term.split(' ').filter(t => t);

            const isWordThere = [];


            if (typeof item.label !== 'undefined') { // Check 'label'

                // Pushing True/False if match is found
                splitTerm.forEach(arr_term => {
                    const search = (`${(item[`label_${(window as any).tourmie_stay_variables_ng.current_language}`] || item[`label`])}`).toLowerCase();
                    isWordThere.push(search.indexOf(arr_term) !== -1);
                });

                const all_words = (this_word) => this_word;

                // Every method will return true if all values are true in isWordThere.
                return isWordThere.every(all_words);

            }
            else if (typeof item.label2 !== 'undefined') { // Check 'label2'

                splitTerm.forEach(arr_term => {
                    const search = (`${(item[`label2_${(window as any).tourmie_stay_variables_ng.current_language}`] || item[`label2`])}`).toLowerCase();
                    isWordThere.push(search.indexOf(arr_term) !== -1);
                });

                const all_words = (this_word) => this_word;

                return isWordThere.every(all_words);

            }
            else if (typeof item.title !== 'undefined') { // Check 'title'

                splitTerm.forEach(arr_term => {
                    const search = (`${(item[`title_${(window as any).tourmie_stay_variables_ng.current_language}`] || item[`title`])}`).toLowerCase();
                    isWordThere.push(search.indexOf(arr_term) !== -1);
                });

                const all_words = (this_word) => this_word;

                return isWordThere.every(all_words);

            }
            else if (typeof item.name !== 'undefined') { // Check 'name'

                splitTerm.forEach(arr_term => {
                    const search = (`${(item[`name_${(window as any).tourmie_stay_variables_ng.current_language}`] || item[`name`])}`).toLowerCase();
                    isWordThere.push(search.indexOf(arr_term) !== -1);
                });

                const all_words = (this_word) => this_word;

                return isWordThere.every(all_words);

            }
            else if (typeof item.description !== 'undefined') { // Check 'description'

                splitTerm.forEach(arr_term => {
                    const search = (`${(item[`description_${(window as any).tourmie_stay_variables_ng.current_language}`] || item[`description`])}`).toLowerCase();
                    isWordThere.push(search.indexOf(arr_term) !== -1);
                });

                const all_words = (this_word) => this_word;

                return isWordThere.every(all_words);

            }
            else if (typeof item.nationality !== 'undefined') {

                splitTerm.forEach(arr_term => {
                    const search = (`${(item[`nationality_${(window as any).tourmie_stay_variables_ng.current_language}`] || item[`nationality`])}`).toLowerCase();
                    isWordThere.push(search.indexOf(arr_term) !== -1);
                });

                const all_words = (this_word) => this_word;

                return isWordThere.every(all_words);

            }
            else if (typeof item.label2_plural !== 'undefined') {

                splitTerm.forEach(arr_term => {
                    const search = (`${(item[`label2_plural_${(window as any).tourmie_stay_variables_ng.current_language}`] || item[`label2_plural`])}`).toLowerCase();
                    isWordThere.push(search.indexOf(arr_term) !== -1);
                });

                const all_words = (this_word) => this_word;

                return isWordThere.every(all_words);

            }
            else if (typeof item.name !== 'undefined') {

                splitTerm.forEach(arr_term => {
                    const search = (`${(item[`name_${(window as any).tourmie_stay_variables_ng.current_language}`] || item[`name`])}`).toLowerCase();
                    isWordThere.push(search.indexOf(arr_term) !== -1);
                });

                const all_words = (this_word) => this_word;

                return isWordThere.every(all_words);

            }

            else
                return false;

        }

        else
            return false;

    }



    async extractGoogleAddressDetails(place: google.maps.places.PlaceResult, short_country?: boolean): Promise<Address> {
        const addressDetails: Address = {
            street: null,
            street_number: null,
            street_formatted: null,
            postal_code: null,
            city: null,
            state: null,
            country: null,
            is_city: null,
            utc_offset: null,
            url: null,
            google_place_id: null,
            types: null,
            longitude: null,
            latitude: null
        };


        if (place && place.address_components) {

            // check if place is city (e.g. Heraklion)
            if (place?.types?.length > 0) {

                addressDetails.types = place.types.toString();

                if (place.types.includes('locality') || place.types.includes('political') || place.types.includes('administrative_area_level_3') || place.types.includes('postal_town'))
                    addressDetails.is_city = true;
                else
                    addressDetails.is_city = false;
            }

            if (place?.utc_offset_minutes === 0 || place?.utc_offset_minutes)
                addressDetails.utc_offset = place.utc_offset_minutes;

            if (place?.place_id)
                addressDetails.google_place_id = place.place_id;

            if (place?.url)
                addressDetails.url = place.url;

            if (place?.formatted_address)
                addressDetails.street_formatted = place.formatted_address || null;

            // tslint:disable-next-line:forin
            for (const key in place.address_components) {

                const record = place.address_components[key];

                if (record.types.includes('route'))
                    addressDetails.street = place.address_components[key].long_name;

                if (record.types.includes('street_number'))
                    addressDetails.street_number = place.address_components[key].long_name;

                if (record.types.includes('locality') || record.types.includes('postal_town'))
                    addressDetails.city = place.address_components[key].long_name;


                if (record.types.includes('postal_code'))
                    addressDetails.postal_code = place.address_components[key].long_name;

                if (record.types.includes('administrative_area_level_3'))
                    addressDetails.state = place.address_components[key].long_name;

                // if (record.types.includes('administrative_area_level_1'))
                //     addressDetails.state = place.address_components[key].long_name;

                if (record.types.includes('administrative_area_level_2'))
                    addressDetails.state = place.address_components[key].long_name;

                if (record.types.includes('country'))
                    if (short_country)
                        addressDetails.country = place.address_components[key].short_name;
                    else
                        addressDetails.country = place?.address_components[key]?.long_name || null;

            }

            if (addressDetails.street && addressDetails.street_number)
                addressDetails.street = `${addressDetails.street} ${addressDetails.street_number}`;


        }

        return Promise.resolve(addressDetails);

    }



    extractYoutubeVideoIdFromURL(youtubeVideoURL: string): string {

        if (!youtubeVideoURL)
            return null;

        const regExp = new RegExp(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);
        const match = youtubeVideoURL.match(regExp);
        return (match && match[7].length === 11) ? match[7] : null;

    }



    extractVimeoVideoIdFromURL(vimeoVideoURL: string): string {

        if (!vimeoVideoURL)
            return null;

        const regExp = new RegExp(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/);
        const match = vimeoVideoURL.match(regExp);

        return (match && match[3]) ? match[3] : null;
    }



    async imageIsBelow2K(base64str: string): Promise<boolean> {

        return new Promise((resolve, reject) => {

            // Initiate the JavaScript Image object.
            const image = new Image();

            // Set the Base64 string return from FileReader as source.
            image.src = base64str;

            // Validate the File Height and Width.
            image.onload = () => {

                if (image.width <= 2560 && image.height <= 1440)
                    return resolve(true);

                return resolve(false);
            };

        });

    }



    getHourMinutesNoTimezone(datetime: string | Date): string {

        if (!datetime)
            return null;

        return (datetime as string).replace('.000Z', '');

    }



    async refreshCustomerData(): Promise<Customer> {
        try {
            // Update current customer in LocalStorage from Database
            const currentCustomer: Customer = this.storageService.getItem('customer');

            if (currentCustomer?.customer_id) {

                let customer = await this.graphqlService.getCustomerData({ customer_id: currentCustomer.customer_id });

                if (customer?.fingerprint === null)
                    delete customer.fingerprint;

                if (customer?.geolocation === null)
                    delete customer.geolocation;

                customer = lodash.extend(currentCustomer, customer); // replace stored object values from these on database
                this.storageService.setItem('customer', customer); // refresh current customer data in LocalStorage

                return Promise.resolve(customer);

            }
            else
                return Promise.resolve(null);


        } catch (error) {
            return Promise.reject(error);
        }
    }



    /**
     * Replace plain URL in text with hypertext
     * * e.g. https://tourmie.com -> '<a href="https://tourmie.com">https://tourmie.com</a>'
     */
    htmlPlainUrlToHypertext(params: { html: string; newTab?: boolean; }): string {

        try {

            if (!params?.html)
                return '';

            // Put the URL to variable $1 after visiting the URL
            const Rexp = /((http|https|ftp):\/\/[\w?=&.+,\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

            // Replace the RegExp content by HTML element
            return params.html.replace(Rexp, `<a href='$1'${params?.newTab ? `target='_blank'` : ''}>$1</a>`);

        } catch (error) {
            return params.html;
        }

    }



    public envIsTourmieStay(): boolean {
        return this.customDomainSettings?.domain && ([
            environment.params.host_frontend_domain,
            'https://stay.tourmie.com',
            'stay.tourmie.com',
            // 'localhost:4000',
        ]).includes(this.customDomainSettings.domain);
    }



    applyCustomDomainData(data: StayEnvironmentSettings): void {

        try {

            this.customDomainSettings = new StayEnvironmentSettings(data || null);

            const root = document.documentElement;

            if (data?.colors && !lodash.isEmpty(data.colors)) {

                window['trm_domain_config'] = data;

                // tslint:disable-next-line:forin
                for (const colorName in data.colors) {
                    root.style.setProperty(`--tourmie-${colorName.replace('_', '-')}`, data.colors[colorName]);
                }

            }


            // if (data?.domain === 'booking.nomad365.org') { // NOTE: replace with -> if (data?.domain === this.hostname)

            // }

            this.storageService.setItem('environment_settings', data);

            this.pubSub.publishEvent('environmentFetched');

            this.showContent = true;

            this.hostIsTourmie = this.envIsTourmieStay();

            if (!environment.production)
                console.log(this.customDomainSettings);

        } catch (error) {
            console.log(error);
        }

    }


}

import { SweetAlertOptions } from 'sweetalert2';
import { Customer, RegistrationReferralData, StayEnvironmentSettings } from './models';
import { FirebaseService } from './services/firebase/firebase.service';
import { onMessage } from '@angular/fire/messaging';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { UtilsService } from './services/utils/utils.service';
import { SeoService } from './services/seo/seo-service.service';
import { StorageService } from './services/storage/storage.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { FullStoryService } from './services/fullstory/fullstory.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Subscription, timer } from 'rxjs';
// import { SwUpdate } from '@angular/service-worker';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TenantPromoModalComponent } from './components/tenant-promo-modal/tenant-promo-modal.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import moment from 'moment';


declare const gtag: any;

const externalScripts: { name: string; url: string; async?: boolean; integrity?: string; crossorigin?: string; }[] = [
  { name: 'googlemaps', async: false, url: 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDzZDFoIXcMXZiTGWQvgZWcguIAXK0W3nk&language=en' },
  { name: 'googletagmanager', async: true, url: 'https://www.googletagmanager.com/gtag/js?id=G-6SQWBDSWF4' },
  { name: 'stripe', async: false, url: 'https://js.stripe.com/v3/' },
  // { name: 'tidio', async: true, url: 'https://code.tidio.co/a8qewdk9jjajqntv2zywzeg1zylbj5ff.js' }
];


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  public title = 'tourmie-stay';

  public customer: Customer;
  public appVersion: string;
  public languageSubscription: Subscription;

  public checkStorageTimer;
  public checkStorageTimerSubscription: Subscription;
  public hasVisitedPropertyList: boolean;

  modalRef: BsModalRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    // private swUpdate: SwUpdate,
    private router: Router,
    public route: ActivatedRoute,
    private renderer: Renderer2,
    private pubSub: NgxPubSubService,
    public utilsService: UtilsService,
    private seo: SeoService,
    private storageService: StorageService,
    private ga: GoogleAnalyticsService,
    private firebaseService: FirebaseService,
    private translate: TranslateService,
    private fullstoryService: FullStoryService,
    private elementRef: ElementRef,
    private modalService: BsModalService
  ) {

    this.appVersion = environment.params.appVersion;

    this.translate.langs = ['en', 'el'];
    this.translate.setDefaultLang('en');
    this.translate.use('en');

  }



  async ngOnInit(): Promise<void> {

    if (this.utilsService.isBrowser) {

      this.languageSubscription = this.pubSub.subscribe('changeLanguage', (language) => {
        this.setLanguage(language);
      });


      this.customer = this.storageService.getItem('customer');

      if (this.customer?.language && (['en', 'el']).includes(this.customer.language)) {
        this.translate.setDefaultLang(this.customer.language);
        this.translate.use(this.customer.language);
        this.renderer.setAttribute(document.querySelector('html'), 'lang', this.customer.language);
        this.storageService.setItem('appLanguage', this.customer.language);
      }
      else {

        const savedLanguage = this.storageService.getItem('appLanguage');

        if (savedLanguage && (['en', 'el']).includes(savedLanguage)) {
          this.translate.setDefaultLang(savedLanguage);
          this.translate.use(savedLanguage);
          this.renderer.setAttribute(document.querySelector('html'), 'lang', savedLanguage);
        }
        else {
          this.translate.setDefaultLang('en');
          this.translate.use('en');
          this.storageService.setItem('appLanguage', 'en');
          this.renderer.setAttribute(document.querySelector('html'), 'lang', 'en');
        }

      }


      // if (this.swUpdate.isEnabled) {

      //   this.swUpdate.available.subscribe(async (event) => {

      //     console.log(`Loaded new version of Tourmie Stay: (v${this.appVersion})`);

      //     await this.utilsService.delay(2000);

      //     window.location.reload();

      //   });

      //   this.swUpdate.checkForUpdate();

      //   setInterval(() => { // interval for checking app update (every 10 minutes)
      //     this.swUpdate.checkForUpdate();
      //   }, 600000);

      // }


      // Uncomment for maintenance mode
      if (environment.params.maintenanceMode)
        this.router.navigate(['/maintenance']);


      // Update FCM Token
      if (this.firebaseService.notificationsAreSupported()) {

        if (this.customer?.customer_id)
          this.firebaseService.updateFcmToken(this.customer);


        onMessage(this.firebaseService.messaging, (payload) => {

          if (!environment.production)
            console.log(payload);

        });

      }


      if (!window.location.pathname.includes('/logout'))
        this.utilsService.refreshCustomerData()
          .then((customer) => {
            this.pubSub.publishEvent('customerDataUpdated', customer); // for navbar
          });


      this.initializePropertyListPagePromoModal();


    }




  }



  ngAfterViewInit(): void {


    if (this.utilsService.isBrowser) {

      this.initializeExternalScripts(); // uncomment to fetch external scripts (gmaps, gtag, stripe, etc.. ) from here instead of index.hmtl

      // tslint:disable-next-line:no-string-literal
      window['__theme'] = 'bs4'; // fix for navbar-user-item dropdown show/hide on click

      if (this.customer?.customer_id) {
        this.ga.setUserID(this.customer.customer_id); // GoogleAnalytics set userID
        this.fullstoryService.identifyUser();
      }

      if (!environment.production && this.customer?.customer_id)
        console.log(this.customer);


      this.router.events.subscribe((event) => {

        if (event instanceof NavigationEnd) {

          this.ga.sendPageView(event.urlAfterRedirects);
          window.scrollTo(0, 0);


          if (event.urlAfterRedirects.includes('/login') || event.urlAfterRedirects.indexOf('/registration') !== -1) { // special classes for login & registration pages

            // For Top Nav only
            // document.body.classList.remove('layout-top-nav');
            document.body.classList.remove('layout-navbar-fixed');
            document.body.classList.remove('sidebar-collapse');

            document.body.classList.remove('register-page');
            document.body.classList.remove('login-page');


            if (event.urlAfterRedirects.indexOf('/registration/guests') !== -1 || event.urlAfterRedirects === '/registration')
              document.body.classList.add('register-page');

            // if (event.urlAfterRedirects.indexOf('/login') !== -1)
            //   document.body.classList.add('login-page');

          }
          else {
            document.body.classList.remove('register-page');
            document.body.classList.remove('login-page');
            // For Top Nav only
            document.body.classList.add('layout-top-nav');
            document.body.classList.add('layout-navbar-fixed');

          }


          this.hideTidioChat(event.urlAfterRedirects);

          this.pubSub.publishEvent('environmentFetched');



          // Handle referral/subreferral query parameters
          try {

            let route = this.route;

            while (route.firstChild) {
              route = route.firstChild;
            }

            const routeSnapshot = route?.snapshot || null;

            if (routeSnapshot?.queryParams?.referral_id)
              this.storageService.setItem('account_referral_id', routeSnapshot.queryParams.referral_id);

            if (routeSnapshot?.queryParams?.referral_name)
              this.storageService.setItem('account_referral_name', routeSnapshot.queryParams.referral_name);

            if (routeSnapshot?.queryParams?.subreferral_id)
              this.storageService.setItem('account_subreferral_id', routeSnapshot.queryParams.subreferral_id);

            if (routeSnapshot?.queryParams?.subreferral_name)
              this.storageService.setItem('account_subreferral_name', routeSnapshot.queryParams.subreferral_name);


            if (!environment.production) {

              const registrationReferralData: RegistrationReferralData = new RegistrationReferralData({
                referral: {
                  id: this.storageService.getItem('account_referral_id') || null,
                  name: this.storageService.getItem('account_referral_name') || null,
                },
                subreferral: {
                  id: this.storageService.getItem('account_subreferral_id') || null,
                  name: this.storageService.getItem('account_subreferral_name') || null,
                },
              });


              console.log(registrationReferralData);

            }

          } catch (error) {

          }

        }

      });

    }

  }



  ngOnDestroy(): void {

    if (this.languageSubscription)
      this.languageSubscription.unsubscribe();

  }



  async setLanguage(languageCode: 'el' | 'en'): Promise<void> {

    const displayAlert: boolean = this.translate.currentLang !== languageCode;

    this.translate.use(languageCode);
    this.translate.setDefaultLang(languageCode);
    // this.initializeMenuPages();
    this.storageService.setItem('appLanguage', languageCode);

    this.renderer.setAttribute(document.querySelector('html'), 'lang', languageCode); // update <html lang="">


    if (displayAlert) {
      let languageMessage: string;
      if (languageCode === 'el')
        languageMessage = `Η γλώσσα άλλαξε σε Ελληνικά`;
      else
        languageMessage = `Language changed to English`;

      this.utilsService.showToast(languageMessage, null, 'success', 3000);


      // Update language in db 'customers' table for current user (if logged in)
      let customer: Customer = this.storageService.getItem('customer');

      if (customer?.customer_id)
        try {

          const response = await this.http.put(`${environment.params.host}/api/stay/utils/update-customer-language`, {
            language_code: languageCode,
            customer_id: customer.customer_id
          }).toPromise();

          customer = await this.utilsService.refreshCustomerData();
          this.pubSub.publishEvent('customerDataUpdated', customer); // for navbar

          return Promise.resolve();

        } catch (error) {

          if (!environment.production)
            console.log(error);

          return Promise.resolve();
        }

    }
  }



  async hideTidioChat(urlAfterRedirects: string): Promise<void> {

    const element = document.getElementById('tidio-chat');

    if (!element && urlAfterRedirects) {
      await this.utilsService.delay(2000);
      this.hideTidioChat(urlAfterRedirects);
      return Promise.resolve();
    }


    if (urlAfterRedirects.indexOf('/login') !== -1
      || urlAfterRedirects.indexOf('/registration') !== -1
      || urlAfterRedirects.indexOf('/properties') !== -1
      || urlAfterRedirects.indexOf('/bookings') !== -1
      || urlAfterRedirects.indexOf('/lease-agreement') !== -1
    )
      document.getElementById('tidio-chat').style.display = 'none';
    else
      document.getElementById('tidio-chat').style.display = 'block';

  }



  initializeExternalScripts(): void {

    // tslint:disable-next-line:curly
    if (this.utilsService.isBrowser) {

      for (const script of externalScripts) {
        const scriptTagManager = this.renderer.createElement('script') as HTMLScriptElement;
        scriptTagManager.src = script.url;

        if (script.async)
          scriptTagManager.async = true;

        if (script.integrity)
          scriptTagManager.integrity = script.integrity;

        if (script.crossorigin)
          scriptTagManager.crossOrigin = script.crossorigin;

        this.renderer.appendChild(this.elementRef.nativeElement, scriptTagManager);


      }




      // Google Maps
      // if (typeof google === 'undefined' || !google) {
      //   const scriptGoogleMaps = document.createElement('script');
      //   scriptGoogleMaps.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDzZDFoIXcMXZiTGWQvgZWcguIAXK0W3nk&language=en';
      //   scriptGoogleMaps.type = 'text/javascript';
      //   // document.body.appendChild(scriptGoogleMaps);
      //   // document.body.removeChild(scriptGoogleMaps);
      //   this.renderer.appendChild(this.elementRef.nativeElement, scriptGoogleMaps);
      // }

      // Google Analytics (gtag.js)
      // const scriptTagManager = this.renderer.createElement('script') as HTMLScriptElement;
      // scriptTagManager.src = `https://www.googletagmanager.com/gtag/js?id=G-6SQWBDSWF4`;
      // scriptTagManager.async = true;
      // this.renderer.appendChild(this.elementRef.nativeElement, scriptTagManager);

      const scriptGtagJS = this.renderer.createElement('script') as HTMLScriptElement;
      const scriptGtagBody = this.renderer.createText(`
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'G-6SQWBDSWF4');
      `);
      this.renderer.appendChild(scriptGtagJS, scriptGtagBody);
      this.renderer.appendChild(this.elementRef.nativeElement, scriptGtagJS);


      // Facebook Pixel
      const scriptFacebookPixel = this.renderer.createElement('script') as HTMLScriptElement;
      scriptFacebookPixel.nonce = 'tAyakTgF';
      const scriptFacebookPixelBody = this.renderer.createText(`
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }; if (!f._fbq) f._fbq = n;
        n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
      }(window,
        document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        fbq('init', '403574804448535');
        fbq('set', 'agent', 'tmgoogletagmanager', '403574804448535');
        fbq('track', "PageView");
        `);
      this.renderer.appendChild(scriptFacebookPixel, scriptFacebookPixelBody);
      this.renderer.appendChild(this.elementRef.nativeElement, scriptFacebookPixel);


      // FullStory
      // if (typeof (window as any).FS === 'undefined' || !(window as any).FS) {
      //   const scriptFullStory = this.renderer.createElement('script') as HTMLScriptElement;
      //   scriptFullStory.src = `https://edge.fullstory.com/s/fs.js`;
      //   scriptFullStory.async = true;
      //   const scriptFullStoryBody = this.renderer.createText(`
      //   window['_fs_debug'] = false;
      //   window['_fs_host'] = 'fullstory.com';
      //   window['_fs_script'] = 'https://edge.fullstory.com/s/fs.js';
      //   window['_fs_org'] = '15A2KN';
      //   window['_fs_namespace'] = 'FS';
      //   (function(m,n,e,t,l,o,g,y){
      //       if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
      //       g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
      //       o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
      //       y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
      //       g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
      //       g.anonymize=function(){g.identify(!!0)};
      //       g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
      //       g.log = function(a,b){g("log",[a,b])};
      //       g.consent=function(a){g("consent",!arguments.length||a)};
      //       g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
      //       g.clearUserCookie=function(){};
      //       g.setVars=function(n, p){g('setVars',[n,p]);};
      //       g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
      //       if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
      //       g._v="1.3.0";
      //   })(window,document,window['_fs_namespace'],'script','user');
      //   `);
      //   this.renderer.appendChild(scriptFullStory, scriptFullStoryBody);
      //   this.renderer.appendChild(this.elementRef.nativeElement, scriptFullStory);
      // }


      // window['trm_applyCustomDomainData'] = this.utilsService.applyCustomDomainData;
      // window['trm_domain_config'] = {};

      // const customDataQueryParams = {
      //   domain: 'booking.nomad365.org' // TODO: add me this.utilsService.hostname
      // };

      // // customDataQueryParams['color_primary'] = '#faaf40';
      // // customDataQueryParams['color_secondary'] = '#27a9e0';
      // // customDataQueryParams['color_primary_text'] = '#212529';
      // // customDataQueryParams['color_secondary_text'] = '#ffffff';

      // const customDataQueryParamsStr = this.utilsService.qs.stringify(customDataQueryParams, { addQueryPrefix: true });

      // const scriptDomainCustomData = this.renderer.createElement('script') as HTMLScriptElement;
      // const scriptDomainCustomDataBody = this.renderer.createText(`
      //   fetch('${environment.params.host}/api/stay/custom-data${customDataQueryParamsStr}')
      //     .then(response => response.json())
      //     .then(data => { window['trm_applyCustomDomainData'](data) })
      //     .catch(error => console.error('Error fetching color values:', error))
      //     .finally(() => console.log('stay-custom-data'));
      // `);
      // this.renderer.appendChild(scriptDomainCustomData, scriptDomainCustomDataBody);
      // this.renderer.appendChild(this.elementRef.nativeElement, scriptDomainCustomData);


    }

  }






  initializePropertyListPagePromoModal(): void { // enable/disable from environment.params.propertyListPageDisplayPromoModal

    // update timestamp on properties list or details visit, interval sto app component, 5 minutes--> modal, close window

    if (this.utilsService.isBrowser)

      if (environment.params.propertyListPageDisplayPromoModal) {

        this.checkStorageTimer = timer(60000, 60000);
        this.checkStorageTimerSubscription = this.checkStorageTimer.subscribe(() => {

          const lastSearchPageVisit = this.storageService.getItem('tenantLastSearchDate');

          if (lastSearchPageVisit) {

            this.hasVisitedPropertyList = true;

            const today = moment().toDate();

            const diff = moment(today).diff(lastSearchPageVisit, 'minutes');

            if (diff >= 5 && window.location.pathname.indexOf('/properties') !== -1) {
              const modalOptions: ModalOptions = {
                id: 10,
                backdrop: 'static',
                animated: true,
                class: 'modal-lg'
              };

              const initialState = {
                type: 'inactive'
              };

              this.modalRef = this.modalService.show(TenantPromoModalComponent, Object.assign({}, modalOptions, { initialState }));
              this.storageService.removeItem('tenantLastSearchDate');
              this.hasVisitedPropertyList = false;

            }

          }

        });

        window.addEventListener('beforeunload', (e) => {

          if (window.location.pathname.indexOf('/properties') !== -1)
            e.preventDefault();

          if (this.hasVisitedPropertyList) {

            const modalOptions: ModalOptions = {
              id: 10,
              backdrop: 'static',
              animated: true,
              class: 'modal-lg'
            };

            const initialState = {
              type: 'close'
            };

            this.storageService.removeItem('tenantLastSearchDate');
            this.modalRef = this.modalService.show(TenantPromoModalComponent, Object.assign({}, modalOptions, { initialState }));
          }
          else
            window.close();

        });

      }


  }



}

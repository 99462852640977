<div class="row" *ngIf="!bookingsLoading && bookings?.length > 0">

  <div class="col-12" *ngFor="let booking of bookings; let i = index;">

    <!-- Desktop -->
    <a class="card shadow-none rounded-lg text-tourmie-darkblue-2" [routerLink]="['/bookings', booking.booking_id]" *ngIf="!isMobile">

      <div class="row no-gutters">

        <div class="col-5">

          <div class="img-wrapper">


            <img class="card-img-top rounded-0 booking-property-image" [src]="booking.property.image_url"
              onError="this.src='/assets/img/placeholders/property-image-placeholder-1.png'">

            <h6 class="badge badge-dark badge-property-type font-size-13 property-type-label">

              {{utilsService.getPropertyTypeLabel(booking.service_subcategory)}}

            </h6>


          </div>

        </div>


        <div class="col-7">

          <div class="card-body py-3 h-100">

            <div class="row">

              <div class="col-12 d-flex justify-content-between">

                <h6 class="text-bold line-clamp-1" title="{{booking.property.title}}">

                  {{booking.property.title | titlecase}}

                </h6>

                <h6>

                  <span class="badge"
                    [ngClass]="{'badge-warning': booking.status === 'pending', 'badge-danger': (['rejected', 'canceled']).includes(booking.status), 'badge-success': booking.status === 'confirmed'}">
                    {{utilsService.getBookingStatusLabel(booking.status)}}
                  </span>

                </h6>
              </div>

            </div>

            <div class="row border-top py-2">

              <div class="col-12">

                <p class="mb-0">
                  {{'GENERIC.LABELS.BOOKING_ID' | translate }}: {{booking.booking_id}}
                </p>

                <p class="mb-0">

                  {{'GENERIC.LABELS.MOVE_IN' | translate }}:

                  <span>
                    {{booking.start_date | date: 'dd MMMM yyyy'}}
                  </span>
                  <span *ngIf="booking.start_time_str || booking?.property?.checkin_time">
                    - {{ booking.start_time_str || booking?.property?.checkin_time }}
                  </span>

                </p>

                <p class="mb-0">

                  {{ 'GENERIC.LABELS.MOVE_OUT' | translate }}:

                  <span>
                    {{booking.end_date | date: 'dd MMMM yyyy'}}
                  </span>
                  <span *ngIf="booking.end_time_str || booking?.property?.checkout_time">
                    - {{ booking.end_time_str || booking?.property?.checkout_time }}
                  </span>

                </p>

                <p class="mb-0">

                  {{ 'PAGES.BOOKING_DETAILS.LABELS.DURATION' | translate }}:

                  <span>{{booking.duration_in_days}} {{ 'GENERIC.LABELS.DAY_MULTIPLE' | translate }}</span>
                </p>

              </div>


            </div>

            <div class="row border-top py-2">

              <div class="col-6">

                <p class="mb-0">
                  {{ 'PAGES.BOOKING_DETAILS.LABELS.PERSONS' | translate }}:
                  <span>{{booking.persons}}</span>
                </p>


              </div>

              <div class="col-6">

                <p class="mb-0">
                  {{ 'GENERIC.LABELS.TOTAL_COST' | translate }}:
                  <span>
                    {{(
                    (booking.amount || 0)
                    + (booking.costs.extra_costs_price || 0)
                    + (booking.costs.extra_amenities_price ||0)
                    + (booking.costs.extra_property_utilities_price || 0)
                    ) | number:'1.2-2'}} €
                  </span>
                </p>

              </div>

            </div>

          </div>

        </div>

      </div>

    </a>

    <!-- Mobile -->
    <a class="card shadow-none rounded-lg text-tourmie-darkblue-2" [routerLink]="['/bookings', booking.booking_id]" *ngIf="isMobile">

      <img class="card-img-top rounded-0 booking-property-image" [src]="booking.property.image_url"
        onError="this.src='/assets/img/placeholders/property-image-placeholder-1.png'">

      <h6 class="badge badge-dark badge-property-type font-size-13 property-type-label">
        {{booking.service_subcategory | titlecase }}
      </h6>

      <div class="card-body">



        <div class="row">

          <div class="col-12 d-flex justify-content-between">


            <h6 class="text-bold line-clamp-2" title="{{booking.property.title}}">

              {{booking.property.title | titlecase}}

            </h6>

            <h6>

              <span class="badge"
                [ngClass]="{'badge-warning': booking.status === 'pending', 'badge-danger': booking.status === 'rejected', 'badge-success': booking.status === 'confirmed'}">

                {{booking.status}}

              </span>

            </h6>


          </div>

        </div>

        <div class="row border-top py-1">

          <div class="col-12">

            <div>
              {{ 'GENERIC.LABELS.MOVE_IN' | translate }}

              <span>{{booking.start_date | date: 'dd/MM/yyyy'}} - {{booking.start_time | date: 'HH:mm'}}</span>
            </div>


            <div>

              {{ 'GENERIC.LABELS.MOVE_OUT' | translate }}

              <span>{{booking.end_date | date:
                'dd/MM/yyyy'}} - {{booking.end_time | date:
                'HH:mm'}}</span>
            </div>


            <div>

              {{ 'PAGES.BOOKING_DETAILS.LABELS.DURATION' | translate }}

              <span>{{booking.duration_in_days}} {{ 'GENERIC.LABELS.DAY_MULTIPLE' | translate }} </span>
            </div>

          </div>


        </div>

        <div class="row border-top py-1">

          <div class="col-12">

            <div>

              {{ 'PAGES.BOOKING_DETAILS.LABELS.PERSONS' | translate }}:

              <span>{{booking.persons}}</span>
            </div>


            <div>

              {{ 'GENERIC.LABELS.TOTAL_COST' | translate }}

              <span>
                {{(
                (booking.amount || 0)
                + (booking.costs.extra_costs_price || 0)
                + (booking.costs.extra_amenities_price ||0)
                + (booking.costs.extra_property_utilities_price || 0)
                ) | number:'1.2-2'}} €
              </span>
            </div>


          </div>


        </div>

      </div>
    </a>

  </div>

</div>



<div class="row" *ngIf="bookingsLoading">

  <div class="col-12">
    <ngx-skeleton-loader [theme]="{ height:'225px'}" count="5"></ngx-skeleton-loader>
  </div>

</div>



<div class="row" *ngIf="!bookingsLoading && bookings?.length === 0">
  <div class="col-12 text-center">
    <h4>
      {{ 'PAGES.CUSTOMER_PROFILE.MENU_BOOKINGS.ERROR_NOT_FOUND' | translate }}
    </h4>
  </div>
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { account_categories, account_provider_types, account_types } from './_accounts';
import { booking_channels } from './_booking_channels';
import { booking_payment_statuses, booking_statuses, booking_types } from './_bookings';
import { countries } from './_countries';
import { identity_verification_types, image_typesidentity_image_types } from './_identity_verification';
import { languages } from './_languages';
import { property_amenities, property_amenities_categories, property_bed_types, property_categories, property_extra_costs, property_general_info_items, property_license_number_types, property_subcategories } from './_properties';
import { timezones } from './_timezones';


export const commonEnvironment = {
    production: true,
    environmentName: 'common',
    params: {
        host: 'https://api.prod.tourmie.com',
        host_frontend: 'https://stay.tourmie.com',
        host_frontend_domain: 'stay.tourmie.com',
        host_frontend_dashboard: 'https://app.tourmie.com',
        appTitle: 'Tourmie Stay',
        appVersion: require('../../package.json').version,
        suid: '2dd7d893-f453-4f2a-a8e3-6997de9ca871',
        gtagTrackingID: 'G-6SQWBDSWF4',
        social: {
            facebook: 'https://www.facebook.com/tourmiestay',
            instagram: 'https://www.instagram.com/tourmiestay',
            linkedin: 'https://www.linkedin.com/company/tourmie-stay'
        },
        showBetaNotification: true,
        reviewMaxRating: 5,
        listYourPropertyPageDisplayPromoModal: false,
        propertyListPageDisplayPromoModal: false,
        favoriteUndoDuration: 5000,
        accommodationDescriptionLength: 500,
        accommodationMaxAmenitiesToShow: 9,
        maintenanceMode: false,
        minBookingDays: 30,
        maxBookingDays: 365,
        providerDetailsVisibleDaysAfterBooking: 7,
        filterParams: {
            maxAdults: 10,
            minAdults: 1,
            maxChildren: 5,
            minChildren: 0,
            maxInfants: 5,
            minInfants: 0
        },
        imagesMaxFileSize: 8 * 1024 * 1024, // 8 MB
        firebase: {
            apiKey: 'AIzaSyBVQmjzt7Zf8-z6HX64O6jFsM0OfpoL-ts',
            authDomain: 'touristhub.firebaseapp.com',
            databaseURL: 'https://touristhub.firebaseio.com',
            projectId: 'touristhub',
            storageBucket: 'touristhub.appspot.com',
            messagingSenderId: '55559450439',
            appId: '1:55559450439:web:b98c3db3261f88e2fae662'
        },
        stripe: {
            pk_test: 'pk_test_51GvOq6F6TJ8ud0a5jybFHZkrKjhe1pWlWUufJUCgEyYAjLKIE1qHyb7rMTow0TIRuLE0JBwE2QhDtwYIk35HC1Je00zsCzDFfu',
            pk_live: 'pk_live_pmuFhnDeWt5eM60I6D3kVZvn00T9av4FyE',
            ca_live: 'ca_HV4nIlMdtu3GcArdPMG42fS4uil1ywqp', // for stripe connect
            ca_test: 'ca_HV4n1g9KihSMagRb06VJKnUxeLNTu5yX' // for stripe connect
        },
        fullstory: {
            orgId: '15A2KN',
            host: 'fullstory.com',
            namespace: 'FS',
            debug: false,
            script: 'edge.fullstory.com/s/fs.js'
        },
        appLanguages: [
            { language: 'English US', code: 'en', flag_url: '/assets/img/flags/rounded/united-states.png' },
            { language: 'Greek', code: 'el', flag_url: '/assets/img/flags/rounded/greece.png' },
        ],
        datatables: {
            options: {
                responsive: true,
                lengthMenu: [[25, 50, 100, 250, 500], [25, 50, 100, 250, 500]],
                language: {
                    // lengthMenu: 'Εμφάνιση _MENU_ εγγραφών ανά σελίδα',
                    // info: 'Εμφάνιση σελίδας _PAGE_ από _PAGES_', // Showing page _PAGE_ of _PAGES_
                    // infoEmpty: 'Δεν βρέθηκαν εγγραφές',
                    infoFiltered: '',
                    // search: 'Αναζήτηση',
                    // emptyTable: 'Δεν βρέθηκαν εγγραφές',
                    paginate: {
                        first: 'First',
                        last: 'Last',
                        next: 'Next',
                        previous: 'Previous',
                    }
                }
            }
        },
        googlemaps: {
            style_1: [
                {
                    featureType: 'all',
                    elementType: 'all',
                    stylers: [
                        {
                            hue: '#e7ecf0'
                        }
                    ]
                },
                {
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'road',
                    elementType: 'all',
                    stylers: [
                        {
                            saturation: -70
                        }
                    ]
                },
                {
                    featureType: 'transit',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'water',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'simplified'
                        },
                        {
                            saturation: -60
                        }
                    ]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#d2edf9'
                        }
                    ]
                }
            ],
            clusterStyle: [
                {
                    width: 30,
                    height: 30,
                    className: 'custom-clustericon-1',
                },
                {
                    width: 35,
                    height: 35,
                    className: 'custom-clustericon-2',
                },
                {
                    width: 40,
                    height: 40,
                    className: 'custom-clustericon-3',
                }
            ]
        }
    },
    properties: {
        categories: property_categories,
        subcategories: property_subcategories,
        amenities: { categories: property_amenities_categories, items: property_amenities },
        bed_types: property_bed_types,
        accommodation_general_info_items: property_general_info_items,
        license_number_types: property_license_number_types,
        extra_costs: {
            items: property_extra_costs
        }
    },
    bookings: {
        channels: booking_channels,
        types: booking_types,
        statuses: booking_statuses,
        payment_statuses: booking_payment_statuses
    },
    identity_verification: {
        types: identity_verification_types,
        image_types: image_typesidentity_image_types,
    },
    faq: {
        categories: {
            general: {
                title_translation_key: 'PAGES.FAQ_PAGE.FAQ_GENERAL_TITLE',
                categories: [
                    { value: 'about', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_GENERAL_CATEGORY_ABOUT', },
                ],
                questions: [
                    { category: 'about', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_GENERAL_GENERAL_WHAT_IS_A_TOURMIE_STAY', },
                    { category: 'about', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_GENERAL_GENERAL_WHY_SHOULD_I_TRUST_TOURMIE_STAY', },
                ]
            },
            tenants: {
                title_translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_TITLE',
                categories: [
                    { value: 'account', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_ACCOUNT', },
                    { value: 'payments', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_PAYMENTS', },
                    { value: 'services', order: 30, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_SERVICES', },
                    { value: 'reservation_policy', order: 40, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_RESERVATION_POLICY', },
                    { value: 'properties', order: 50, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_CATEGORY_PROPERTIES', },
                ],
                questions: [
                    { category: 'account', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_ACCOUNT_HOW_DO_I_CREATE_A_TOURMIE_STAY_ACCOUNT_AS_A_TENANT' },
                    { category: 'account', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_ACCOUNT_CAN_I_DELETE_MY_ACCOUNT' },
                    { category: 'account', order: 30, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_ACCOUNT_WHAT_IS_THE_REGISTRATION_FEE' },
                    { category: 'payments', order: 40, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_HOW_AND_WHEN_DO_I_PAY_THE_MONTHLY_RENT' },
                    { category: 'payments', order: 50, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_HOW_DO_I_ENTER_MY_CARD_DETAILS' },
                    { category: 'payments', order: 60, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_IS_THE_PAYMENT_METHOD_USED_BY_TOURMIE_STAY_SECURE' },
                    { category: 'payments', order: 70, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_WHO_WILL_ISSUE_THE_MONTHLY_RENT_PAYMENT_RECEIPT' },
                    { category: 'payments', order: 80, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_HOW_ARE_THE_MONTHLY_FIXED_CHARGE_AND_MAINTENANCE_FEES_OF_THE_ACCOMMODATION_BEEN_PAID' },
                    { category: 'payments', order: 90, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_DO_I_NEED_TO_PAY_ANY_DEPOSIT_FOR_THE_ACCOMMODATION' },
                    { category: 'payments', order: 100, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PAYMENTS_DO_I_NEED_TO_PROVIDE_A_GUARANTEE_FOR_THE_ACCOMMODATION_IN_CASE_OF_DAMAGE_OR_LOSS' },
                    { category: 'properties', order: 110, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_WHO_CAN_STAY_IN_TOURMIE_STAY_ACCOMMODATION' },
                    { category: 'properties', order: 120, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_HOW_LONG_CAN_I_BOOK_AN_ACCOMMODATION' },
                    { category: 'properties', order: 130, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_CAN_I_STAY_FOR_MORE_THAN_A_YEAR' },
                    { category: 'properties', order: 140, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_WHAT_IS_THE_MINIMUM_BOOKING_PERIOD_FOR_RENTING_AN_ACCOMMODATION' },
                    { category: 'properties', order: 150, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_PROPERTIES_WHAT_IS_THE_PROCEDURE_I_NEED_TO_FOLLOW_TO_BOOK_A_TOURMIE_STAY_ACCOMODATION' },
                    { category: 'reservation_policy', order: 160, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_RESERVATION_POLICY_CAN_I_CANCEL_MY_RESERVATION' },
                    { category: 'reservation_policy', order: 170, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_RESERVATION_POLICY_WHAT_HAPPENS_IF_THE_ACCOMMODATION_DOES_NOT_RESPONSIVE_THE_DESCRIPTION_OF_THE_LISTING' },
                    { category: 'services', order: 180, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_SERVICES_ARE_PETS_ALLOWED' },
                    { category: 'services', order: 190, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_SERVICES_ARE_PARTIES_OR_EVENTS_ALLOWED' },
                    { category: 'services', order: 200, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_SERVICES_IS_THE_ACCOMMODATION_FULLY_EQUIPPED_AND_FURNISHED_UPON_MY_BOOKING' },
                    { category: 'services', order: 210, translation_key: 'PAGES.FAQ_PAGE.FAQ_TENANTS_SERVICES_DOES_THE_PROPERTY_HAVE_A_CLEANING_SERVICE' },
                ]
            },
            landlords: {
                title_translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_TITLE',
                categories: [
                    { value: 'account', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_ACCOUNT', },
                    { value: 'payments', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_PAYMENTS', },
                    { value: 'services', order: 30, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_SERVICES', },
                    { value: 'basic_info', order: 40, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_BASIC_INFO', },
                    { value: 'properties', order: 50, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_CATEGORY_PROPERTIES', },
                ],
                questions: [
                    { category: 'account', order: 10, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_ACCOUNT_HOW_DO_I_CREATE_A_TOURMIE_STAY_ACCOUNT_AS_A_TENANT' },
                    { category: 'account', order: 20, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_ACCOUNT_CAN_I_DELETE_MY_ACCOUNT' },
                    { category: 'account', order: 30, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_ACCOUNT_WHAT_IS_THE_REGISTRATION_FEE' },
                    { category: 'account', order: 40, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_ACCOUNT_WHAT_COMMISSION_DOES_THE_TOURMIE_STAY_RECEIVE_ON_EACH_BOOKING' },
                    { category: 'payments', order: 50, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_WHEN_DO_I_GET_PAID_THE_MONTHLY_RENT' },
                    { category: 'payments', order: 60, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_CAN_I_CHARGE_EXTRA_FOR_SERVICES_E_G_PARKING_CLEANING' },
                    { category: 'payments', order: 70, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_CAN_I_INCORPORATE_THE_MONTHLY_COSTS_OF_THE_PROPERTY_ALONG_WITH_THE_MONTHLY_RENT' },
                    { category: 'payments', order: 80, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_WHO_ISSUES_THE_MONTHLY_RENT_PAYMENT_RECEIPT' },
                    { category: 'payments', order: 90, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_DO_I_NEED_TO_RECIEVE_A_DEPOSIT_FOR_MY_ACCOMMODATION' },
                    { category: 'payments', order: 100, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_WHAT_IS_THE_MINIMUM_RENT_I_CAN_SET_IN_TOURMIE_STAY_FOR_MY_ACCOMODATION' },
                    { category: 'payments', order: 110, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_IN_CASE_OF_DAMAGE_TO_MY_ACCOMMODATION_HOW_CAN_I_BE_COMPENSATED' },
                    { category: 'payments', order: 120, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PAYMENTS_WHAT_HAPPENS_IF_A_TENANT_CLAIMS_THAT_MY_ACCOMMODATION_DOES_NOT_RESPOND_THE_DESCRIPTION_OF_THE_LISTING' },
                    { category: 'services', order: 130, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_SERVICES_WHAT_DOES_MY_ACCOMMODATION_NEED_TO_HAVE_IN_ORDER_TO_REGISTER_WITH_TOURMIE_STAY' },
                    { category: 'services', order: 140, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_SERVICES_WHAT_FACILITIES_CAN_I_PROVIDE_TO_MY_GUESTS' },
                    { category: 'services', order: 150, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_SERVICES_IN_TOURMIE_STAY_DOES_THE_PROPERTY_NEED_TO_BE_FURNISHED' },
                    { category: 'basic_info', order: 160, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_BASIC_INFO_WHAT_ARE_THE_MINIMUM_REQUIREMENTS_TO_REGISTER_MY_ACCOMMODATION_IN_TOURMIE_STAY' },
                    { category: 'basic_info', order: 170, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_BASIC_INFO_DOES_MY_PROPERTY_NEED_TO_HAVE_PROPERTY_REGISTRATION_NUMBER_A_M_A' },
                    { category: 'basic_info', order: 180, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_BASIC_INFO_CAN_A_TENANT_CONTACT_ME_OFF_PLATFORM' },
                    { category: 'basic_info', order: 190, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_BASIC_INFO_CAN_I_ADD_A_CANCELLATION_POLICY' },
                    { category: 'properties', order: 200, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_WHAT_ARE_MY_OBLIGATIONS_TOWARD_THE_GUESTS_WHO_WILL_USE_MY_ACCOMMODATION' },
                    { category: 'properties', order: 210, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_WHAT_KIND_OF_ACCOMMODATIONS_CAN_BE_REGISTERED' },
                    { category: 'properties', order: 220, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_CAN_I_REGISTER_A_HOTEL_ROOM_THAT_HAS_A_SPECIAL_OPERATING_LOGO_ESL' },
                    { category: 'properties', order: 230, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_WHAT_IS_THE_MINIMUM_LENGTH_OF_STAY' },
                    { category: 'properties', order: 240, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_WHAT_HAPPENS_IF_THE_TENANT_CAUSES_ANY_DAMAGE_TO_MY_PROPERTY' },
                    { category: 'properties', order: 250, translation_key: 'PAGES.FAQ_PAGE.FAQ_LANDLORDS_PROPERTIES_DOES_TOURMIE_STAY_OFFER_PROPERTY_INSURANCE' },
                ]
            }
        }
    },
    accounts: {
        categories: account_categories,
        types: account_types,
        providers: {
            types: account_provider_types
        }
    },
    countries: countries,
    languages: languages,
    timezones: timezones,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

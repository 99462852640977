<!-- TODO: translation -->
<!-- <ng-template #tenantPromoModal> -->
<div class="modal-header">
  <h5 class="modal-title text-tourmie-darkblue" id="amenitiesModalLabel">
    <strong>
      Tenant title
    </strong>
  </h5>

  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true"><i class="fas fa-times fa-xs"></i></span>
  </button>
</div>

<div class="modal-body p-0">
  <div class="row no-gutters">
    <div class="col-sm-12 col-md-12 col-lg-6 p-3">
      <div class="row">
        <div class="col-12">
          <p>
            Tenant message
          </p>
        </div>
        <div class="col-12">
          <form #tenantPromoForm="ngForm" autocomplete="off">
            <div class="row">
              <!-- Tenant full name -->
              <div class="col-12 px-3">
                <div class="form-group mb-3">
                  <label for="fullName">
                    {{'PAGES.CONTACT.INPUTS.NAME.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="fullName" name="fullName" placeholder="{{'PAGES.CONTACT.INPUTS.NAME.PLACEHOLDER' | translate }}"
                    #fullName="ngModel" [(ngModel)]="tenantFormData.full_name"
                    [ngClass]="{'is-invalid': fullName.invalid && (fullName.dirty || fullName.touched)}" aria-describedby="fullNameHelp" required>

                  <small id="fullNameHelp" class="form-text text-danger" *ngIf="fullName.invalid && (fullName.dirty || fullName.touched)">
                    <span *ngIf="fullName.errors.required">{{ 'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                  </small>

                </div>
              </div>

              <!-- Tenant phone -->
              <div class="col-12 px-3">
                <div class="form-group mb-3 mobile-phone-container">

                  <label for="mobileTelephone">
                    {{'GENERIC.LABELS.PHONE' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <ngx-intl-tel-input [cssClass]="phone.invalid && (phone.dirty && phone.touched) ? 'form-control is-invalid' : 'form-control'"
                    [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
                    [selectedCountryISO]="CountryISO.Greece" [maxLength]="24" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                    [separateDialCode]="true" id="phone" name="phone" #phone="ngModel" [(ngModel)]="tenantPhone" (ngModelChange)="updateTenantMobileNumber();"
                    aria-describedby="phoneHelp" required>
                  </ngx-intl-tel-input>

                  <small id="phoneHelp" class="form-text text-danger" *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                    <span *ngIf="phone.errors.required && phone.touched">{{
                      'GENERIC.LABELS.REQUIRED_FIELD' | translate }}</span>
                  </small>

                </div>
              </div>

              <!-- Tenant email -->
              <div class="col-12 px-3">
                <div class="form-group mb-3">

                  <label for="email">
                    {{'PAGES.CONTACT.INPUTS.EMAIL.LABEL' | translate }}
                  </label>

                  <!-- <app-required-field-label [type]="'asterisk_tooltip'">
                                </app-required-field-label> -->

                  <input type="email" class="form-control" id="email" name="email" pattern="[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,50}$"
                    placeholder="{{'PAGES.CONTACT.INPUTS.EMAIL.PLACEHOLDER' | translate }}" #email="ngModel" [(ngModel)]="tenantFormData.email"
                    [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}" aria-describedby="emailHelp">

                  <small id="emailHelp" class="form-text text-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                    {{ 'GENERIC.LABELS.ENTER_A_VALID_EMAIL' | translate }}
                  </small>

                </div>
              </div>

              <!-- Form actions -->
              <div class="col-12 pt-3 d-flex justify-content-center">
                <button type="button" class="btn btn-tourmie-primary" (click)="submitTenantForm()" [disabled]="fullName.invalid || phone.invalid">
                  {{'GENERIC.BUTTONS.SUBMIT' | translate }}</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
    <div class="col-6" *ngIf="!isMobile">
      <img src="/assets/img/list-modal-landlord-contact/need-more-info.webp" class="w-100 landlord-modal-img">
    </div>
  </div>
</div>
<!-- </ng-template> -->
<div class="row mx-0" [ngClass]="{'px-3 vh-75': isMobile}">

  <div class="col-sm-12 col-md-6 col-lg-6 d-none d-lg-none d-xl-flex px-0">

    <div class="w-100 vh-100 login-background"></div>

  </div>

  <div class="col-sm-8 col-md-6 col-lg-6 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0 align-self-center login-column">


    <div *ngIf="!role" class="choose-role-container">

      <div class="row mb-2">

        <div class="col-12">

          <div class="login-logo">
            <a [routerLink]="[ '/' ]">
              <img [src]="utilsService?.customDomainSettings?.logo?.dark" alt="TourmieStay" class="image-logo">
            </a>
          </div>

        </div>

      </div>

      <!-- Login as Tenant -->
      <div class="row mt-2">

        <div class="col-sm-12 col-md-4 col-lg-6 offset-md-4 offset-lg-3 login-as-column">

          <a href="javascript:void(0);" (click)="goToTenantLoginForm()" [gaEvent]="{ title: 'click_signin_as_tenant' }">

            <div class="card card-box box-login">

              <div class="box-login-header">

                <h4 class="box-login-title">

                  <div class="row">

                    <div class="col-2">

                      <i class="far fa-user"></i>

                    </div>


                    <div class="col-10">
                      {{ 'GENERIC.BUTTONS.SIGN_IN' | translate }} {{
                      'PAGES.REGISTRATION.LABELS.AS_TENANT' | translate }}
                    </div>

                  </div>

                </h4>

              </div>

            </div>

          </a>

        </div>

      </div>


      <!-- Login as Landlord -->
      <div class="row">

        <div class="col-sm-12 col-md-4 col-lg-6 offset-md-4 offset-lg-3 login-as-column">

          <a [href]="environment?.params?.host_frontend_dashboard ? environment.params.host_frontend_dashboard + '/?source=tourmie_stay' : '/'"
            [gaEvent]="{ title: 'click_signin_as_landlord' }">

            <div class="card card-box box-login">

              <div class="box-login-header">

                <h4 class="box-login-title">

                  <div class="row">

                    <div class="col-2">

                      <i class="far fa-building"></i>

                    </div>


                    <div class="col-10">
                      {{ 'GENERIC.BUTTONS.SIGN_IN' | translate }} {{
                      'PAGES.REGISTRATION.LABELS.AS_LANDLORD' | translate }}
                    </div>

                  </div>
                </h4>

              </div>

            </div>

          </a>

        </div>

      </div>

    </div>



    <div class="login-box mx-auto" [ngClass]="{'mt-5': isMobile}" *ngIf="role && role === 'tenant'">

      <div class="login-logo mb-3">
        <a [routerLink]="[ '/' ]">
          <img [src]="utilsService?.customDomainSettings?.logo?.dark" alt="TourmieStay" class="image-logo">
        </a>
      </div>

      <div class="card login-card">

        <div class="card-body login-card-body">

          <!-- <p class="login-box-msg">Sign in to start your session</p> -->
          <form #loginForm="ngForm" (ngSubmit)="submitForm();" autocomplete="off">

            <div class="row mb-4 text-center">

              <div class="col-12">
                <h5 class="font-size-20"> {{ 'PAGES.LOGIN.LABELS.SIGN_IN_TO_TOURMIE' | translate }}
                </h5>
              </div>

            </div>


            <div class="row">
              <div class="col-12">
                <div class="input-group">

                  <input type="email" class="form-control" pattern="[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,50}$"
                    placeholder="{{ 'PAGES.LOGIN.INPUTS.EMAIL.PLACEHOLDER' | translate }}" name="email" aria-describedby="emailHelp" #email="ngModel"
                    [(ngModel)]="credentials.email" required>

                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-envelope"></span>
                    </div>
                  </div>

                </div>

                <small id="emailHelp" class="form-text text-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                  {{ 'GENERIC.LABELS.ENTER_A_VALID_EMAIL' | translate }}
                </small>

              </div>
            </div>


            <div class="row mt-2">
              <div class="col-12">

                <div class="input-group">

                  <input type="password" class="form-control" minlength="6" name="password"
                    placeholder="{{ 'PAGES.LOGIN.INPUTS.PASSWORD.PLACEHOLDER' | translate }}" #password="ngModel" [(ngModel)]="credentials.password"
                    aria-describedby="passwordHelp" required>

                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-lock"></span>
                    </div>
                  </div>

                </div>

                <small id="passwordHelp" class="form-text text-danger" *ngIf="password.invalid && (password.dirty || password.touched)">
                  <span *ngIf="password.errors.required">
                    {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
                  </span>
                  <span *ngIf="password.errors.minlength">
                    {{ 'GENERIC.LABELS.PASSWORD_LENGTH_WARNING' | translate }}
                  </span>
                  <!-- <span> Password must be at least 6 characters long </span> -->
                </small>

              </div>
            </div>

            <div class="row mt-3">

              <div class="col-12">


                <button type="submit" class="btn btn-tourmie-primary btn-block login-button" [disabled]="loginForm.invalid" [ladda]="isConnecting"
                  [gaEvent]="{ title: 'click_signin' }">
                  {{ 'GENERIC.BUTTONS.SIGN_IN' | translate }}
                </button>


                <!-- <button type="submit"
                  class="btn btn-tourmie-secondary btn-block login-button ladda-button" data-style="expand-right" disabled="">
                  <span class="ladda-label"> Sign in </span>
                  <span class="ladda-spinner"></span>
                </button> -->
              </div>

              <div class="col-12 text-center">
                <a class="btn btn-link text-muted btn-sm mt-1" href="javascript:void(0);" (click)="openPasswordResetModal(passwordResetModaltemplate)"
                  [gaEvent]="{ title: 'click_forgot_my_password' }">
                  {{ 'PAGES.LOGIN.LABELS.FORGOT_MY_PASSWORD' | translate }}
                </a>
              </div>

            </div>

            <div class="row mt-3">
              <div class="col-sm-12">

                <p class="text-center mb-2">{{ 'PAGES.LOGIN.LABELS.DONT_HAVE_AN_ACCOUNT' | translate }}
                </p>
                <button class="btn btn-tourmie-secondary btn-block" [disabled]="isConnecting" (click)="goToRegistration();"
                  [gaEvent]="{ title: 'click_create_new_tenant_account' }">
                  {{ 'PAGES.LOGIN.BUTTONS.CREATE_ACCOUNT' | translate }}
                </button>

              </div>
            </div>


          </form>

        </div>

      </div>


      <!-- <div class="row">

        <div class="col-12 text-center">

          <a href="javascript:void(0);" class="btn btn-link text-tourmie-secondary" (click)="role = null;">
            <i class="fas fa-arrow-left mr-2"></i><span> {{'GENERIC.BUTTONS.BACK' | translate | replaceTourmieStay:
              utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}</span>
          </a>

        </div>

      </div> -->



      <!-- Pass reset modal -->
      <ng-template #passwordResetModaltemplate>
        <div class="modal-header bg-tourmie-secondary">
          <h5 class="modal-title pull-left">
            {{ 'PAGES.LOGIN.LABELS.PASSWORD_RESET_TITLE' | translate }}
          </h5>
          <span class="close pull-right" aria-label="Close" (click)="passwordResetModalRef.hide()">
            <span aria-hidden="true"><i class="fas fa-times fa-xs"></i></span>
          </span>

        </div>
        <div class="modal-body">

          <div class="row">
            <div class="col-12">

              <p class="text-dark">
                {{ 'PAGES.LOGIN.LABELS.PASSWORD_RESET_SUBTITLE' | translate }}
              </p>

              <div class="form-group">

                <label class="mb-1" for="resetPasswordEmail">
                  {{ 'PAGES.LOGIN.INPUTS.PASSWORD_RESET_EMAIL.LABEL' | translate }}
                </label>

                <input class="form-control" id="resetPasswordEmail" name="resetPasswordEmail" type="email" pattern="[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,50}$"
                  placeholder="{{ 'PAGES.LOGIN.INPUTS.PASSWORD_RESET_EMAIL.PLACEHOLDER' | translate }}" #resetPasswordEmail="ngModel"
                  [(ngModel)]="emailToResetPassword" aria-describedby="resetPasswordEmailHelp" required />


                <small id="resetPasswordEmailHelp" class="form-text text-danger"
                  *ngIf="resetPasswordEmail.invalid && (resetPasswordEmail.dirty || resetPasswordEmail.touched)">
                  {{'GENERIC.LABELS.ENTER_A_VALID_EMAIL' | translate }}
                </small>
              </div>

            </div>
          </div>

        </div>

        <div class="modal-footer justify-content-end">

          <button type="button" class="btn btn-tourmie-primary" (click)="resetPasswordRequest()" [ladda]="isSubmittingPasswordResetRequest"
            [disabled]="!emailToResetPassword || resetPasswordEmail.invalid">
            {{'PAGES.LOGIN.BUTTONS.RESET_PASSWORD' | translate }}
          </button>

        </div>

      </ng-template>

    </div>



    <div class="row mt-4" *ngIf="role && role === 'tenant'">

      <div class="col-4 col-md-4 col-lg-4 offset-4 offset-md-4 offset-lg-4">

        <app-language-switch [hideLabel]="false" [onlyFlag]="false"></app-language-switch>

      </div>

    </div>



  </div>

</div>
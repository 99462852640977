<div id="layoutError">

  <div class="container vh-100">

    <div class="row h-100 justify-content-center">
      <div class="col-lg-6 my-auto">
        <div class="text-center">

          <img class="mb-4 img-error" src="assets/img/error-404-monochrome.svg" />

          <p class="lead">{{ 'PAGES.PAGE_404.URL_NOT_FOUND' | translate }}</p>

          <a [routerLink]="[ '/' ]"><i class="fas fa-arrow-left mr-1"></i>
            {{ 'PAGES.PAGE_404.RETURN_TO_HOME' | translate }}
          </a>

        </div>
      </div>
    </div>

  </div>

</div>
<div class="register-box mt-3">

  <div class="row">

    <div class="col-12">

      <div class="register-logo mb-3">
        <a [routerLink]="['/']">
          <img [src]="utilsService.customDomainSettings?.logo?.dark" alt="TourmieStay" title="TourmieStay" class="image-logo">
        </a>
      </div>

      <div class="card registration-card">

        <div class="card-header text-center">
          <h4>
            {{ 'PAGES.REGISTRATION.LABELS.STEP_TITLE_GUEST_DETAILS' | translate }}
          </h4>
          <small *ngIf="currentStep === 0" class="text-muted">
            {{currentStep+1}}. {{ 'PAGES.REGISTRATION.LABELS.STEP_SUBTITLE_USER_DETAILS' | translate }}
          </small>
          <small *ngIf="currentStep === 1" class="text-muted">
            {{currentStep+1}}. {{ 'PAGES.REGISTRATION.LABELS.STEP_SUBTITLE_ADDITIONAL_INFORMATION' |
            translate}}
          </small>

        </div>

        <div class="card-body register-card-body">

          <form #registerForm="ngForm" autocomplete="off">

            <!-- Customer details -->
            <div class="row" [hidden]="currentStep != 0">

              <!-- First Name -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group mb-3">
                  <label for="firstName">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_FIRST_NAME.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="firstName" name="firstName"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_FIRST_NAME.PLACEHOLDER' | translate }}" #firstName="ngModel"
                    [(ngModel)]="registrationData.first_name" [ngClass]="{'is-invalid': firstName.invalid && (firstName.dirty || firstName.touched)}"
                    aria-describedby="firstNameHelp" required>

                  <small id="firstNameHelp" class="form-text text-danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <span *ngIf="firstName.errors.required">{{ 'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                  </small>

                </div>
              </div>

              <!-- Last Name -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group mb-3">

                  <label for="lastName">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_LAST_NAME.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="lastName" name="lastName"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_LAST_NAME.PLACEHOLDER' | translate }}" #lastName="ngModel"
                    [(ngModel)]="registrationData.last_name" [ngClass]="{'is-invalid': lastName.invalid && (lastName.dirty || lastName.touched)}"
                    aria-describedby="lastNameHelp" required>

                  <small id="lastNameHelp" class="form-text text-danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <span *ngIf="lastName.errors.required">
                      {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
                    </span>
                  </small>

                </div>
              </div>

              <!-- Email -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group mb-3">

                  <label for="email">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_EMAIL.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="email" class="form-control" id="email" name="email" pattern="[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,50}$"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_EMAIL.PLACEHOLDER' | translate }}" #email="ngModel" [(ngModel)]="registrationData.email"
                    [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}" aria-describedby="emailHelp" required>

                  <small id="emailHelp" class="form-text text-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                    {{ 'GENERIC.LABELS.ENTER_A_VALID_EMAIL' | translate }}
                  </small>

                </div>
              </div>

              <!-- Phone -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group mb-3 mobile-phone-container">

                  <label for="mobileTelephone">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_MOBILE_TELEPHONE.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <ngx-intl-tel-input
                    [cssClass]="mobileTelephone.invalid && (mobileTelephone.dirty && mobileTelephone.touched) ? 'form-control is-invalid' : 'form-control'"
                    [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
                    [selectedCountryISO]="CountryISO.Greece" [maxLength]="24" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                    [separateDialCode]="true" id="mobileTelephone" name="mobileTelephone" #mobileTelephone="ngModel" [(ngModel)]="userMobilePhone"
                    (ngModelChange)="updateUserMobileNumber();" aria-describedby="mobileTelephoneHelp" required>
                  </ngx-intl-tel-input>

                  <small id="mobileTelephoneHelp" class="form-text text-danger"
                    *ngIf="mobileTelephone.invalid && (mobileTelephone.dirty || mobileTelephone.touched)">
                    <span *ngIf="mobileTelephone.errors.required && mobileTelephone.touched">{{
                      'GENERIC.LABELS.REQUIRED_FIELD' | translate }}</span>
                  </small>

                </div>
              </div>

              <!-- Password -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group mb-3">

                  <label for="password">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_PASSWORD.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="password" class="form-control" id="password" minlength="6" name="password"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_PASSWORD.PLACEHOLDER' | translate }}" #password="ngModel"
                    [(ngModel)]="registrationData.password" [ngClass]="{'is-invalid': password.invalid && (password.dirty || password.touched)}"
                    aria-describedby="passwordHelp" autocomplete="new-password" required>

                  <small id="passwordHelp" class="form-text text-danger" *ngIf="password.invalid && (password.dirty || password.touched)">
                    <span *ngIf="password.errors.required">
                      {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
                    </span>
                    <span *ngIf="password.errors.minlength">
                      {{ 'GENERIC.LABELS.PASSWORD_LENGTH_WARNING' | translate }}
                    </span>
                  </small>

                </div>
              </div>

              <!-- Password confirm -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group mb-3">

                  <label for="passwordConfirm">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_PASSWORD_CONFIRM.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="password" class="form-control" id="passwordConfirm" minlength="6" name="passwordConfirm"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_PASSWORD_CONFIRM.PLACEHOLDER' | translate }}" #passwordConfirm="ngModel"
                    [(ngModel)]="registrationData.password_confirm"
                    [ngClass]="{'is-invalid': passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)}"
                    aria-describedby="passwordConfirmHelp" required>

                  <small id="passwordConfirmHelp" class="form-text text-danger"
                    *ngIf="passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)">
                    <span *ngIf="passwordConfirm.errors.required">{{
                      'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                    <span *ngIf="passwordConfirm.errors.minlength">{{
                      'GENERIC.LABELS.PASSWORD_LENGTH_WARNING' | translate }}
                    </span>
                  </small>

                </div>
              </div>

              <!-- Birth Date -->
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group mb-3">
                  <label for="birthDate">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_BIRTH_DATE.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>


                  <input type="date" class="form-control" id="birthDate" name="birthDate" placeholder="" [attr.min]="minDateOfBirth" [attr.max]="maxDateOfBirth"
                    #birthDate="ngModel" [(ngModel)]="registrationData.birth_date"
                    [ngClass]="{'is-invalid': birthDate.invalid && (birthDate.dirty || birthDate.touched)}" aria-describedby="birthDateHelp" required>

                  <small id="birthDateHelp" class="form-text text-danger" *ngIf="birthDate.invalid && (birthDate.dirty || birthDate.touched)">
                    <span *ngIf="birthDate.errors.required">
                      {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
                    </span>
                  </small>

                </div>
              </div>

              <!-- identity dropdown -->
              <div class="col-sm-12 col-md-6 col-lg-6">

                <div class="form-group mb-3">

                  <label for="identityField">{{ 'PAGES.REGISTRATION.INPUTS.USER_IDENTITY_TYPE.LABEL' |
                    translate}}</label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <ng-select id="identityField" placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_IDENTITY_TYPE.PLACEHOLDER' | translate }}"
                    [items]="identity_verification_types" [multiple]="false" bindLabel="label" bindValue="value" [closeOnSelect]="true"
                    [(ngModel)]="registrationData.identity_type" name="identityField" #identityField="ngModel" aria-describedby="identityFieldHelp"
                    (change)="nullify()" required>
                    <ng-template ng-label-tmp let-item="item">
                      <div title="item">{{utilsService.getCustomerIdentityTypeLabel(item.value)}}</div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div title="item">{{utilsService.getCustomerIdentityTypeLabel(item.value)}}</div>
                    </ng-template>
                  </ng-select>

                  <small id="identityFieldHelp" class="form-text text-danger" *ngIf="identityField.invalid && (identityField.dirty || identityField.touched)">
                    {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
                  </small>
                </div>
              </div>


              <!-- 1. passport number -->
              <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="registrationData.identity_type === 'passport'">
                <div class="form-group mb-3">
                  <label for="passportNumber">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_PASSPORT_NUMBER.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="passportNumber" name="passportNumber"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_PASSPORT_NUMBER.PLACEHOLDER' | translate }}" #passportNumber="ngModel"
                    [(ngModel)]="registrationData.passport_number"
                    [ngClass]="{'is-invalid': passportNumber.invalid && (passportNumber.dirty || passportNumber.touched)}" aria-describedby="passportNumberHelp"
                    required>

                  <small id="passportNumberHelp" class="form-text text-danger"
                    *ngIf="passportNumber.invalid && (passportNumber.dirty || passportNumber.touched)">
                    <span *ngIf="passportNumber.errors.required">
                      {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
                    </span>
                  </small>

                </div>
              </div>


              <!-- 2. driving license -->
              <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="registrationData.identity_type === 'driving_license'">
                <div class="form-group mb-3">
                  <label for="drivingLicense">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_DRIVING_LICENSE.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="drivingLicense" name="drivingLicense"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_DRIVING_LICENSE.PLACEHOLDER' | translate }}" #drivingLicense="ngModel"
                    [(ngModel)]="registrationData.driving_license_number"
                    [ngClass]="{'is-invalid': drivingLicense.invalid && (drivingLicense.dirty || drivingLicense.touched)}" aria-describedby="drivingLicenseHelp"
                    required>

                  <small id="drivingLicenseHelp" class="form-text text-danger"
                    *ngIf="drivingLicense.invalid && (drivingLicense.dirty || drivingLicense.touched)">
                    <span *ngIf="drivingLicense.errors.required">
                      {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
                    </span>
                  </small>
                </div>
              </div>


              <!-- 3.  identity card -->
              <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="registrationData.identity_type === 'identity_card'">
                <div class="form-group mb-3">
                  <label for="identityCard">
                    {{ 'PAGES.REGISTRATION.INPUTS.USER_IDENTITY_CARD.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="identityCard" name="identityCard"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_IDENTITY_CARD.PLACEHOLDER' | translate }}" #identityCard="ngModel"
                    [(ngModel)]="registrationData.identity_card_number"
                    [ngClass]="{'is-invalid': identityCard.invalid && (identityCard.dirty || identityCard.touched)}" aria-describedby="identityCardHelp"
                    required>

                  <small id="identityCardHelp" class="form-text text-danger" *ngIf="identityCard.invalid && (identityCard.dirty || identityCard.touched)">
                    <span *ngIf="identityCard.errors.required">
                      {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
                    </span>
                  </small>
                </div>
              </div>




              <!-- Error Messages -->
              <div class="col-12">

                <!-- Password -->
                <div class="alert alert-danger"
                  *ngIf="registrationData.password && registrationData.password_confirm && (registrationData.password !== registrationData.password_confirm) && (!password.errors && !passwordConfirm.errors)">
                  {{ 'GENERIC.LABELS.PASSWORDS_NOT_MATCH_WARNING' | translate }}
                </div>

              </div>

            </div>

            <!-- Address / Business Info -->
            <div class="row" [hidden]="currentStep != 1">

              <!-- STREET -->
              <div class="col-sm-12 col-md-7 col-lg-7">
                <div class="form-group mb-3">

                  <label for="addressStreet">
                    {{ 'GENERIC.INPUTS.ADDRESS.STREET.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="addressStreet" name="addressStreet"
                    placeholder="{{ 'GENERIC.INPUTS.ADDRESS.STREET.PLACEHOLDER' | translate }}"
                    [ngClass]="{'is-invalid': addressStreet.invalid && (addressStreet.dirty || addressStreet.touched)}" #addressStreet="ngModel"
                    #addressStreetView [(ngModel)]="registrationData.address.street" aria-describedby="addressStreetHelp" required>

                  <small id="addressStreetHelp" class="form-text text-danger" *ngIf="addressStreet.invalid && (addressStreet.dirty || addressStreet.touched)">
                    <span *ngIf="addressStreet.errors.required">{{
                      'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                  </small>

                </div>
              </div>

              <!-- COUNTRY -->
              <div class="col-sm-12 col-md-5 col-lg-5">
                <div class="form-group mb-3">

                  <label for="addressCountry">{{
                    'GENERIC.INPUTS.ADDRESS.COUNTRY.LABEL' |
                    translate }}</label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <ng-select id="addressCountry" placeholder="{{ 'GENERIC.INPUTS.ADDRESS.COUNTRY.PLACEHOLDER' | translate }}" [items]="countries"
                    [multiple]="false" bindLabel="name" bindValue="code" [closeOnSelect]="true" [(ngModel)]="registrationData.address.country"
                    [ngClass]="{'is-invalid': addressCountry.invalid && (addressCountry.dirty || addressCountry.touched)}" name="addressCountry"
                    #addressCountry="ngModel" aria-describedby="addressCountryHelp" required>
                  </ng-select>

                  <small id="addressCountryHelp" class="form-text text-danger"
                    *ngIf="addressCountry.invalid && (addressCountry.dirty || addressCountry.touched)">
                    <span *ngIf="addressCountry.errors.required">{{
                      'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                  </small>

                </div>
              </div>

              <!-- STATE -->
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="form-group mb-3">

                  <label for="addressState">{{ 'GENERIC.INPUTS.ADDRESS.STATE.LABEL' |
                    translate
                    }}</label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="addressState" name="addressState"
                    placeholder="{{ 'GENERIC.INPUTS.ADDRESS.STATE.PLACEHOLDER' | translate }}" #addressState="ngModel"
                    [(ngModel)]="registrationData.address.state" aria-describedby="addressStateHelp">

                </div>
              </div>

              <!-- CITY -->
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="form-group mb-3">

                  <label for="addressCity">{{ 'GENERIC.INPUTS.ADDRESS.CITY.LABEL' |
                    translate
                    }}</label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="addressCity" name="addressCity"
                    placeholder="{{ 'GENERIC.INPUTS.ADDRESS.CITY.PLACEHOLDER' | translate }}" #addressCity="ngModel" [(ngModel)]="registrationData.address.city"
                    [ngClass]="{'is-invalid': addressCity.invalid && (addressCity.dirty || addressCity.touched)}" aria-describedby="addressCityHelp" required>

                  <small id="addressCityHelp" class="form-text text-danger" *ngIf="addressCity.invalid && (addressCity.dirty || addressCity.touched)">
                    <span *ngIf="addressCity.errors.required">{{ 'GENERIC.LABELS.REQUIRED_FIELD'
                      |
                      translate}}</span>
                  </small>

                </div>
              </div>

              <!-- POSTAL_CODE -->
              <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="form-group mb-3">

                  <label for="addressPostalCode">{{
                    'GENERIC.INPUTS.ADDRESS.POSTAL_CODE.LABEL' |
                    translate }}</label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="addressPostalCode" name="addressPostalCode"
                    placeholder="{{ 'GENERIC.INPUTS.ADDRESS.POSTAL_CODE.PLACEHOLDER' | translate }}" #addressPostalCode="ngModel"
                    [(ngModel)]="registrationData.address.postal_code"
                    [ngClass]="{'is-invalid': addressPostalCode.invalid && (addressPostalCode.dirty || addressPostalCode.touched)}"
                    aria-describedby="addressPostalCodeHelp" required>

                  <small id="addressPostalCodeHelp" class="form-text text-danger"
                    *ngIf="addressPostalCode.invalid && (addressPostalCode.dirty || addressPostalCode.touched)">
                    <span *ngIf="addressPostalCode.errors.required">{{
                      'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                  </small>

                </div>
              </div>



              <!-- Business checkbox -->
              <div class="col-sm-12 col-lg-6 offset-lg-3 text-center py-2">

                <div class="form-group">
                  <div class="icheck-tourmie-secondary">

                    <input type="checkbox" id="businessCheckbox" name="businessCheckbox" value="agree" #businessCheckbox="ngModel" [(ngModel)]="isBusiness"
                      (change)="onBusinessCheckboxChange($event)">

                    <label for="businessCheckbox">
                      {{ 'PAGES.REGISTRATION.BUTTONS.BUSINESS_CHECKBOX' | translate }}
                    </label>

                  </div>
                </div>

              </div>

              <!-- BUSINESS NAME -->
              <div class="col-sm-12 col-md-6 col-lg-6" [hidden]="!isBusiness">
                <div class="form-group mb-3">

                  <label for="businessName">
                    {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_NAME.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="businessName" name="businessName"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_NAME.PLACEHOLDER' | translate }}" #businessName="ngModel"
                    [(ngModel)]="registrationData.business_name" aria-describedby="businessNameHelp"
                    [ngClass]="{'is-invalid': businessName.invalid && (businessName.dirty || businessName.touched)}" [required]="isBusiness">

                  <small id="businessNameHelp" class="form-text text-danger" *ngIf="businessName.invalid && (businessName.dirty || businessName.touched)">
                    <span *ngIf="businessName.errors.required">{{ 'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                  </small>

                </div>
              </div>

              <!-- BUSINESS TITLE -->
              <div class="col-sm-12 col-md-6 col-lg-6" [hidden]="!isBusiness">
                <div class="form-group mb-3">

                  <label for="businessTitle">
                    {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_TITLE.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="businessTitle" name="businessTitle"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_TITLE.PLACEHOLDER' | translate }}" #businessTitle="ngModel"
                    [(ngModel)]="registrationData.business_title"
                    [ngClass]="{'is-invalid': businessTitle.invalid && (businessTitle.dirty || businessTitle.touched)}" aria-describedby="businessTitleHelp"
                    [required]="isBusiness">

                  <small id="businessTitleHelp" class="form-text text-danger" *ngIf="businessTitle.invalid && (businessTitle.dirty || businessTitle.touched)">
                    <span *ngIf="businessTitle.errors.required">{{ 'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                  </small>

                </div>
              </div>

              <!-- BUSINESS EMAIL -->
              <div class="col-sm-12 col-md-6 col-lg-6" [hidden]="!isBusiness">
                <div class="form-group mb-3">

                  <label for="businessEmail">
                    {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_EMAIL.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="email" class="form-control" id="businessEmail" name="businessEmail" pattern="[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,50}$"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_EMAIL.PLACEHOLDER' | translate }}" #businessEmail="ngModel"
                    [(ngModel)]="registrationData.business_email"
                    [ngClass]="{'is-invalid': businessEmail.invalid && (businessEmail.dirty || businessEmail.touched)}" aria-describedby="businessEmailHelp"
                    [required]="isBusiness">

                  <small id="businessEmailHelp" class="form-text text-danger" *ngIf="businessEmail.invalid && (businessEmail.dirty || businessEmail.touched)">
                    {{ 'GENERIC.LABELS.ENTER_A_VALID_EMAIL' | translate }}
                  </small>

                </div>
              </div>

              <!-- BUSINESS PHONE -->
              <div class="col-sm-12 col-md-6 col-lg-6" [hidden]="!isBusiness">
                <div class="form-group mb-3 mobile-phone-container">

                  <label for="businessTelephone">
                    {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_PHONE.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <ngx-intl-tel-input
                    [cssClass]="businessTelephone.invalid && (businessTelephone.dirty && businessTelephone.touched) ? 'form-control is-invalid' : 'form-control'"
                    [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
                    [selectedCountryISO]="CountryISO.Greece" [maxLength]="24" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                    [separateDialCode]="true" id="businessTelephone" name="businessTelephone" #businessTelephone="ngModel" [(ngModel)]="businessPhone"
                    (ngModelChange)="updateBusinessMobileNumber();" aria-describedby="businessTelephoneHelp" [required]="isBusiness">
                  </ngx-intl-tel-input>

                  <small id="businessTelephoneHelp" class="form-text text-danger"
                    *ngIf="businessTelephone.invalid && (businessTelephone.dirty || businessTelephone.touched)">
                    <span *ngIf="businessTelephone.errors.required && businessTelephone.touched">{{
                      'GENERIC.LABELS.REQUIRED_FIELD' | translate }}</span>
                  </small>

                </div>
              </div>



              <!-- TAX/VAT ID -->
              <div class="col-sm-12 col-md-6 col-lg-6" [hidden]="!isBusiness">
                <div class="form-group mb-3">

                  <label for="vatID">
                    {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_TAX_ID.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="vatID" name="vatID"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_TAX_ID.LABEL' | translate }}"
                    [ngClass]="{'is-invalid': vatID.invalid && (vatID.dirty || vatID.touched)}" #vatID="ngModel" [(ngModel)]="registrationData.tax_id"
                    (change)="onVatNumberChange();" aria-describedby="vatIDHelp" [required]="isBusiness">

                  <small id="vatIDHelp" class="form-text text-danger" *ngIf="vatID.invalid && (vatID.dirty || vatID.touched)">
                    <span *ngIf="vatID.errors.required">{{ 'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                  </small>

                </div>
              </div>



              <!-- TAX AUTHORITY -->
              <div class="col-sm-12 col-md-6 col-lg-6" [hidden]="!isBusiness">
                <div class="form-group mb-3">

                  <label for="taxAuthority">
                    {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_TAX_AUTHORITY.LABEL' | translate }}
                  </label>

                  <app-required-field-label [type]="'asterisk_tooltip'">
                  </app-required-field-label>

                  <input type="text" class="form-control" id="taxAuthority" name="taxAuthority"
                    placeholder="{{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_TAX_AUTHORITY.PLACEHOLDER' | translate }}" #taxAuthority="ngModel"
                    [ngClass]="{'is-invalid': taxAuthority.invalid && (taxAuthority.dirty || taxAuthority.touched)}"
                    [(ngModel)]="registrationData.tax_authority" aria-describedby="taxAuthorityHelp" [required]="isBusiness">

                  <small id="taxAuthorityHelp" class="form-text text-danger" *ngIf="taxAuthority.invalid && (taxAuthority.dirty || taxAuthority.touched)">
                    <span *ngIf="taxAuthority.errors.required">{{ 'GENERIC.LABELS.REQUIRED_FIELD' |
                      translate}}</span>
                  </small>

                </div>
              </div>

              <!-- Terms & Policy checkbox -->
              <div class="col-sm-12 col-lg-6 offset-lg-3">

                <div class="form-group">
                  <div class="icheck-tourmie-secondary">

                    <input type="checkbox" id="agreePrivacyPolicy" name="PrivacyPolicy" value="agree" #agreePrivacyPolicy="ngModel"
                      [(ngModel)]="privacyPolicyAgreed" required>





                    <label for="agreePrivacyPolicy">
                      {{ 'PAGES.REGISTRATION.LABELS.I_AGREE_TO_TOURMIE_PRIVACY_POLICY_PREFIX' | translate }}<a [routerLink]="[ '/privacy-policy' ]"
                        target="_blank"> {{
                        'PAGES.REGISTRATION.LABELS.PRIVACY_POLICY' |
                        translate}} </a>
                    </label>

                    <small id="agreePrivacyPolicyHelp" class="form-text text-danger"
                      *ngIf="!privacyPolicyAgreed && (agreePrivacyPolicy.dirty || agreePrivacyPolicy.touched)">
                      <span>
                        {{ 'PAGES.REGISTRATION.LABELS.MUST_AGREE_PRIVACY_POLICY' | translate }}
                      </span>
                    </small>

                  </div>
                </div>

                <div class="form-group">

                  <div class="icheck-tourmie-secondary mt-3">

                    <input type="checkbox" id="agreeTerms" name="terms" value="agree" #agreeTerms="ngModel" [(ngModel)]="termsAgreed" required>

                    <label for="agreeTerms">
                      {{ 'PAGES.REGISTRATION.LABELS.I_AGREE_TO_TOURMIE_TERMS_OF_SERVICE_PREFIX' | translate }}
                      <a href="javascript:void(0)" (click)="getTermsOfService(termsOfServiceModal)">
                        {{
                        'PAGES.REGISTRATION.LABELS.I_AGREE_TO_TOURMIE_TERMS_OF_SERVICE_SUFFIx' | translate }}
                      </a>
                    </label>

                    <small id="agreeTermsHelp" class="form-text text-danger" *ngIf="!termsAgreed && (agreeTerms.dirty || agreeTerms.touched)">
                      <span>
                        {{ 'PAGES.REGISTRATION.LABELS.MUST_AGREE_TERMS_OF_SERVICE' | translate }}
                      </span>
                    </small>

                  </div>
                </div>
              </div>

            </div>

            <!-- Form buttons -->
            <div class="col-12 text-center pt-3 d-flex" [ngClass]="currentStep === 0 ? 'justify-content-end' : 'justify-content-between'">

              <button *ngIf="currentStep === 1" type="button" class="btn btn-tourmie-secondary" (click)="previousStep()"> <i class="fas fa-arrow-left"></i>
                &nbsp; {{
                'GENERIC.BUTTONS.PREVIOUS' | translate }}
              </button>
              <button *ngIf="currentStep === 0" type="button" class="btn btn-tourmie-secondary" (click)="nextStep()" [ladda]="isCheckingCustomerEmail"
                [disabled]="firstName.invalid
                            || lastName.invalid
                            || email.invalid
                            || mobileTelephone.invalid
                            || password.invalid
                            || passwordConfirm.invalid
                            || birthDate.invalid
                            || !registrationData.identity_type
                            || (registrationData.identity_type == 'passport' && !registrationData.passport_number)
                            || (registrationData.identity_type == 'driving_license' && !registrationData.driving_license_number)
                            || (registrationData.identity_type == 'identity_card' && !registrationData.identity_card_number)
                            || (registrationData.password && registrationData.password_confirm && (registrationData.password !==
                            registrationData.password_confirm) && (!password.errors && !passwordConfirm.errors))">
                {{
                'GENERIC.BUTTONS.NEXT' | translate }} &nbsp;<i class="fas fa-arrow-right"></i>
              </button>

              <button *ngIf="currentStep === 1" type="button" role="button" class="btn btn-tourmie-secondary" (click)="submitForm()" [ladda]="isSubmitting"
                [disabled]="firstName.invalid
                            || lastName.invalid
                            || email.invalid
                            || mobileTelephone.invalid
                            || password.invalid
                            || passwordConfirm.invalid
                            || addressCountry.invalid
                            || addressCity.invalid
                            || addressStreet.invalid
                            || addressPostalCode.invalid
                            || !privacyPolicyAgreed
                            || !termsAgreed
                            || (isBusiness && (businessName.invalid || businessTitle.invalid || businessEmail.invalid || businessTelephone.invalid || taxAuthority.invalid || vatID.invalid))
                            || (registrationData.password && registrationData.password_confirm && (registrationData.password !== registrationData.password_confirm) && (!password.errors && !passwordConfirm.errors))">
                {{ 'PAGES.REGISTRATION.BUTTONS.REGISTER' | translate }}
              </button>
            </div>

          </form>

        </div>

      </div>

      <div class="container pb-5">
        <div class="row text-center mt-2" [hidden]="currentStep != 0">
          <div class="col-lg-12  text-center">
            <span>
              {{ 'PAGES.REGISTRATION.LABELS.DO_YOU_ALREADY_HAVE_ACCOUNT' | translate }}
            </span>
          </div>
          <div class="col-lg-12 text-center">
            <a [routerLink]="[ '/login' ]" [queryParams]="{ role: 'tenant' }" class="text-tourmie-secondary">
              {{ 'PAGES.REGISTRATION.LABELS.CLICK_HERE_TO_LOGIN' | translate }}
            </a>
          </div>
        </div>
      </div>


    </div>
  </div>

</div>


<ng-template #termsOfServiceModal>
  <div class="modal-header">
    <h5 class="modal-title">

      {{'PAGES.TERMS_OF_SERVICE.TITLE' | translate }}

    </h5>
    <span class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true"><i class="fas fa-times fa-xs"></i></span>
    </span>
  </div>

  <div class="modal-body">

    <div class="row">

      <div class="col-12">

        <div [innerHTML]="terms | safe: 'html'"></div>

      </div>

    </div>

  </div>
</ng-template>
<div class="modal-header bg-tourmie-primary">
  <h5 class="modal-title pull-left">Sign in</h5>

  <span class="close pull-right" aria-label="Close" (click)="closeModal();">
    <span aria-hidden="true"><i class="fas fa-times fa-xs"></i></span>
  </span>

</div>

<div class="modal-body">

  <div class="card login-card">

    <div class="card-body login-card-body">

      <!-- <p class="login-box-msg">Sign in to start your session</p> -->
      <form #loginForm="ngForm" (ngSubmit)="submitForm();" autocomplete="off">

        <!-- <div class="row mb-4 text-center">

                    <div class="col-12">
                        <h5 class="font-size-22"> {{ 'PAGES.LOGIN.LABELS.SIGN_IN_TO_TOURMIE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay' }} </h5>
                    </div>

                </div> -->


        <div class="row">
          <div class="col-12">

            <div class="input-group">

              <input type="email" class="form-control" pattern="[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,50}$"
                placeholder="{{ 'PAGES.LOGIN.INPUTS.EMAIL.PLACEHOLDER' | translate }}" name="email" aria-describedby="emailHelp" #email="ngModel"
                [(ngModel)]="credentials.email" required>

              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>

            </div>

            <small id="emailHelp" class="form-text text-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
              {{ 'GENERIC.LABELS.ENTER_A_VALID_EMAIL' | translate }}
            </small>

          </div>
        </div>


        <div class="row mt-2">
          <div class="col-12">

            <div class="input-group">

              <input type="password" class="form-control" minlength="6" name="password"
                placeholder="{{ 'PAGES.LOGIN.INPUTS.PASSWORD.PLACEHOLDER' | translate }}" #password="ngModel" [(ngModel)]="credentials.password"
                aria-describedby="passwordHelp" required>

              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>

            </div>

            <small id="passwordHelp" class="form-text text-danger" *ngIf="password.invalid && (password.dirty || password.touched)">

              <span *ngIf="password.errors.required">
                {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate }}
              </span>
              <span *ngIf="password.errors.minlength">
                {{ 'GENERIC.LABELS.PASSWORD_LENGTH_WARNING' | translate }}
              </span>

            </small>

          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">


            <button type="submit" class="btn btn-tourmie-secondary btn-block login-button" [disabled]="loginForm.invalid" [ladda]="isConnecting">
              {{ 'PAGES.LOGIN.BUTTONS.SIGN_IN' | translate }}
            </button>


          </div>

          <!-- <div class="col-12 text-center">
                        <a class="btn btn-link text-muted btn-sm mt-1" href="javascript:void(0);"
                            (click)="openPasswordResetModal(passwordResetModaltemplate)">
                            {{ 'PAGES.LOGIN.LABELS.FORGOT_MY_PASSWORD' | translate }}
                        </a>
                    </div> -->

        </div>

        <div class="row mt-3">

          <div class="col-sm-12">

            <p class="text-center mb-2">{{ 'PAGES.LOGIN.LABELS.DONT_HAVE_AN_ACCOUNT' | translate }}</p>
            <button type="button" class="btn btn-outline-tourmie-primary btn-block" [disabled]="isConnecting" (click)="goToRegistration();">
              {{ 'PAGES.LOGIN.BUTTONS.CREATE_ACCOUNT' | translate }}
            </button>

          </div>

        </div>


      </form>

    </div>

  </div>

</div>
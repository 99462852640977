<div class="wrapper">

    <!-- Header -->
    <app-navbar [hidden]="!utilsService.showContent"></app-navbar>

    <!-- Content -->
    <!-- class="content-wrapper" -->
    <!-- Navbar height = 60px; -->
    <div [hidden]="!utilsService.showContent">
        <router-outlet></router-outlet>
    </div>

    <!-- Footer -->
    <div class="layout-footer-fixed" [hidden]="!utilsService.showContent">
        <app-footer></app-footer>
    </div>

    <!-- Move to top button -->
    <!-- <app-move-to-top *ngIf="!isMobile && !hideScrollToTop"></app-move-to-top> -->

</div>
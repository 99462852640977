import { Address } from './Address';
import { Booking } from './Booking';
import { ViesData } from './VIES';
import { DeviceFingerprint } from './DeviceFingerprint';
import { Property } from './Property';
import { IdentityDocument } from './IdentityDocument';

export class Customer {
        id?: number;
        customer_id: string;
        first_name: string;
        last_name: string;
        fullname: string;
        email: string;
        password_reset_token?: string;
        phone: string;
        language: 'en' | 'el';
        type: 'individual' | 'business';
        identity_verified: boolean;
        identity_type?: 'identity_card' | 'passport' | 'driving_license';
        identity_card_number?: string;
        passport_number?: string;
        driving_license_number?: string;
        profile_pic_url?: string;
        profile_pic_filename?: string;
        tax_id?: string;
        address?: Address;
        stripe_customer_id?: string;
        verified?: boolean;
        verification_token?: string;
        vies_data?: ViesData;
        archived?: boolean;
        archived_at?: Date;
        created_at?: Date;
        birth_date: Date;

        // Extras
        bookings?: Booking[];
        fingerprint?: DeviceFingerprint;
        geolocation?: any;
        fcm_token?: string; // firebase cloud messaging token
        access_token?: string;
        favorite_services?: Property[];
        identity_docs?: IdentityDocument[];

        constructor(props?: Customer) {
                this.id = props?.id || null;
                this.customer_id = props?.customer_id || null;
                this.first_name = props?.first_name || null;
                this.last_name = props?.last_name || null;
                this.fullname = props?.fullname || null;
                this.email = props?.email || null;
                this.password_reset_token = props?.password_reset_token || null;
                this.phone = props?.phone || null;
                this.language = props?.language || 'en';
                this.type = props?.type || 'individual';
                this.identity_verified = props?.identity_verified || null;
                this.identity_type = props?.identity_type || null;
                this.identity_card_number = props?.identity_card_number || null;
                this.passport_number = props?.passport_number || null;
                this.driving_license_number = props?.driving_license_number || null;
                this.profile_pic_url = props?.profile_pic_url || null;
                this.profile_pic_filename = props?.profile_pic_filename || null;
                this.tax_id = props?.tax_id || null;
                this.address = props?.address || null;
                this.stripe_customer_id = props?.stripe_customer_id || null;
                this.verified = props?.verified || false;
                this.verification_token = props?.verification_token || null;
                this.created_at = props?.created_at || null;
                this.vies_data = props?.vies_data || null;
                this.archived = props?.archived || false;
                this.archived_at = props?.archived_at || null;
                this.bookings = props?.bookings || [];
                this.fingerprint = props?.fingerprint || null;
                this.geolocation = props?.geolocation || null;
                this.fcm_token = props?.fcm_token || null;
                this.access_token = props?.access_token || null;
                this.archived = props?.archived || false;
                this.archived_at = props?.archived_at || null;
                this.birth_date = props?.birth_date || null;
                this.favorite_services = props?.favorite_services || [];
                this.identity_docs = props?.identity_docs || [];

        }
}

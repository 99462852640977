<!-- TODO: Add animations for remove/ undo -->

<div class="row" *ngIf="!favoritesLoading && favorites?.length > 0">

  <div class="col-12" *ngFor="let favorite of favorites; let i = index;">

    <!-- Desktop -->
    <div class="card shadow-none rounded-lg" *ngIf="!isMobile">

      <a class="row no-gutters text-tourmie-darkblue-2" *ngIf="!favorite.removedFromFavorites" [routerLink]="['/properties', favorite.service_id]"
        target="_blank">

        <div class="col-5">

          <div class="img-wrapper">

            <img class="card-img-top rounded-0" [src]="favorite.image_url" onError="this.src='/assets/img/placeholders/property-image-placeholder-1.png'">

            <h6 class="badge badge-dark badge-property-type font-size-13 property-type-label">
              {{utilsService.getPropertyTypeLabel(favorite.subcategory)}}
            </h6>

            <div class="property-fav-btn">


              <button type="button" class="btn shadow-none px-1 py-0" (click)="removeFromFavorites($event, i);">

                <i class="fas fa-heart"></i>

              </button>

            </div>

          </div>

        </div>

        <div class="col-md-7">

          <div class="card-body">
            <div class="row">
              <div class="col-6">

                <h6 class="text-bold line-clamp-2" title="{{favorite.title}}">
                  {{ favorite.title }}
                </h6>

                <h6 class="font-size-14">

                  <i class="fas fa-map-marker-alt text-danger"></i>

                  <em>
                    {{favorite.address.city}}
                  </em>

                </h6>

                <div>

                  <rating class="text-warning h5" [(ngModel)]="favorite.reviews_rating.avg_rating" [max]="reviewMaxRating" readonly="true">
                  </rating>

                  <span class="font-size-16 text-muted pl-1">({{favorite.reviews_rating.total_reviews}})</span>

                </div>
              </div>

              <div class="col-6 text-right">

                <span *ngIf="favorite.discounted_price === null">

                  <strong class="h4">
                    {{favorite.price_per_month}} &euro;
                  </strong>

                  {{'GENERIC.LABELS.PER_MONTH' | translate }}
                </span>

                <span *ngIf="favorite.discounted_price !== null">

                  <strong class="h4">
                    {{favorite.discounted_price}} &euro;
                  </strong>

                  <s>
                    {{favorite.price_per_month}} &euro;
                  </s>

                  {{'GENERIC.LABELS.PER_MONTH' | translate }}
                </span>

              </div>
            </div>


          </div>

          <div class="card-footer mb-0 pb-0 border-top bg-white">

            <div class="row pt-2">

              <div class="col-4 text-center" *ngIf="favorite.area_size">

                <img class="icon-size-35" src="assets/img/generalinfo/area.png" alt="Area Size m^2" title="Area Size">


                <p class="mb-0">
                  {{favorite.area_size}} m<sup>2</sup>
                </p>

              </div>

              <div class="col-4 text-center" *ngIf="favorite.bedrooms">

                <img class="icon-size-35" src="assets/img/generalinfo/bedroom.png" title="{{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate }}"
                  alt="{{favorite.bedrooms}} {{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate }}">

                <p class="mb-0">{{favorite.bedrooms}}

                  <span *ngIf="favorite.bedrooms === 1">

                    {{'GENERIC.LABELS.BEDROOM_SINGLE' | translate }}

                  </span>

                  <span *ngIf="favorite.bedrooms > 1">

                    {{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate }}

                  </span>


                </p>

              </div>

              <div class="col-4 text-center" *ngIf="favorite.bathrooms">

                <img class="icon-size-35" src="assets/img/generalinfo/bathroom.png" title="{{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate }}"
                  alt="{{favorite.bathrooms}} {{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate }}">

                <p class="mb-0">{{favorite.bathrooms}}


                  <span *ngIf="favorite.bathrooms === 1">

                    {{'GENERIC.LABELS.BATHROOM_SINGLE' | translate }}

                  </span>

                  <span *ngIf="favorite.bathrooms > 1">

                    {{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate }}

                  </span>
                </p>

              </div>


            </div>



          </div>

        </div>

      </a>

      <!-- UNDO btn -->
      <div class="row my-3" *ngIf="favorite.removedFromFavorites">

        <div class="col-12 text-center">

          <div class="btn btn-default px-5 py-3" (click)="undoFavoriteRemoval(i)">

            {{'GENERIC.BUTTONS.UNDO' | translate }}

          </div>

        </div>

      </div>

    </div>




    <!-- Mobile -->
    <a class="card shadow-none rounded-lg text-tourmie-darkblue-2" [routerLink]="['/properties', favorite.service_id]" target="_blank"
      *ngIf="isMobile && !favorite.removedFromFavorites">

      <img class="card-img-top rounded-0" [src]="favorite.image_url" onError="this.src='/assets/img/placeholders/property-image-placeholder-1.png'">

      <h6 class="badge badge-dark badge-property-type font-size-13 property-type-label">
        {{favorite.subcategory | titlecase }}
      </h6>

      <div class="property-fav-btn">


        <button type="button" class="btn shadow-none px-1 py-0" (click)="removeFromFavorites($event, i);">

          <i class="fas fa-heart"></i>

        </button>

      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-6">

            <h6 class="text-bold" title="{{favorite.title}}">
              {{ favorite.title }}
            </h6>

            <h6 class="font-size-14">
              <i class="fas fa-map-marker-alt text-danger"></i>
              <em>
                {{favorite.address.city}}
              </em>
            </h6>

            <div>
              <rating class="text-warning h5" [(ngModel)]="favorite.reviews_rating.avg_rating" [max]="reviewMaxRating" readonly="true">
              </rating>
              <span class="font-size-16 text-muted pl-1">({{favorite.reviews_rating.total_reviews}})</span>
            </div>

          </div>

          <div class="col-6 text-right">

            <span *ngIf="favorite.discounted_price === null">
              <strong class="h4">
                {{favorite.price_per_month}} &euro;
              </strong>
              {{'GENERIC.LABELS.PER_MONTH' | translate }}
            </span>

            <span *ngIf="favorite.discounted_price !== null">
              <strong class="h4">
                {{favorite.discounted_price}} &euro;
              </strong>
              <s>
                {{favorite.price_per_month}} &euro;
              </s>
              {{'GENERIC.LABELS.PER_MONTH' | translate }}
            </span>

          </div>
        </div>


      </div>

      <div class="card-footer mb-0 pb-0 border-top bg-white">

        <div class="row pt-2">

          <div class="col-4 text-center" *ngIf="favorite.area_size">

            <img class="icon-size-35" src="assets/img/generalinfo/area.png" alt="Area Size m^2" title="Area Size">
            <p class="mb-0">
              {{favorite.area_size}} m<sup>2</sup>
            </p>

          </div>

          <div class="col-4 text-center" *ngIf="favorite.bedrooms">

            <img class="icon-size-35" src="assets/img/generalinfo/bedroom.png" title="{{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate }}"
              alt="{{favorite.bedrooms}} {{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate }}">

            <p class="mb-0">{{favorite.bedrooms}}


              <span *ngIf="favorite.bedrooms === 1">

                {{'GENERIC.LABELS.BEDROOM_SINGLE' | translate }}

              </span>

              <span *ngIf="favorite.bedrooms > 1">

                {{'GENERIC.LABELS.BEDROOM_MULTIPLE' | translate }}

              </span>

            </p>

          </div>

          <div class="col-4 text-center" *ngIf="favorite.bathrooms">

            <img class="icon-size-35" src="assets/img/generalinfo/bathroom.png" title="{{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate }}"
              alt="{{favorite.bathrooms}} {{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate }}">

            <p class="mb-0">{{favorite.bathrooms}}


              <span *ngIf="favorite.bathrooms === 1">

                {{'GENERIC.LABELS.BATHROOM_SINGLE' | translate }}

              </span>

              <span *ngIf="favorite.bathrooms > 1">

                {{'GENERIC.LABELS.BATHROOM_MULTIPLE' | translate }}

              </span>
            </p>

          </div>


        </div>



      </div>
    </a>

  </div>

</div>


<div class="row" *ngIf="favoritesLoading">

  <div class="col-12" *ngFor="let item of 5 | enumerate">

    <ngx-skeleton-loader [theme]="{ height:'225px'}"></ngx-skeleton-loader>

  </div>

</div>


<div class="row" *ngIf="!favoritesLoading && favorites?.length === 0">

  <div class="col-12 text-center">
    <h4>

      {{ 'PAGES.CUSTOMER_PROFILE.MENU_FAVORITES.ERROR_NOT_FOUND' | translate }}

    </h4>
  </div>

</div>
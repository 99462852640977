import { UtilsService } from '../../services/utils/utils.service';
import { Booking } from '../../models/Booking';
import { environment } from '../../../environments/environment';
import { GraphqlService } from '../../services/graphql/graphql.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-booking-submission-page',
  templateUrl: './booking-submission-page.component.html',
  styleUrls: ['./booking-submission-page.component.scss']
})
export class BookingSubmissionPageComponent implements OnInit, OnDestroy {

  public booking: Booking;
  private routerSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private graphqlService: GraphqlService,
    public utilsService: UtilsService
  ) { }

  ngOnInit(): void {

    this.routerSubscription = this.route.params.subscribe(async (params) => {
      try {

        if (params.booking_id) {

          const booking = await this.graphqlService.getBooking(params.booking_id);

          if (!environment.production)
            console.log(booking);


          if (!booking || !booking.booking_id)
            this.router.navigate(['/']);

          this.booking = booking;

        }
        // else
        // this.router.navigate(['/bookings']);

      } catch (error) {

        if (!environment.production)
          console.log(error);

      }

    });

  }

  ngOnDestroy(): void {
    if (this.routerSubscription)
      this.routerSubscription.unsubscribe();
  }


  async goToMyBookings(): Promise<void> {

    this.router.navigate([`/bookings`]);

  }



  async goToBooking(): Promise<void> {

    if (!this.booking?.booking_id) {
      this.goToMyBookings();
      return Promise.resolve();
    }

    this.router.navigate([`/bookings`, this.booking.booking_id]);

  }


}

import { Amenity } from './Amenity';
import { Property } from './Property';
import { Account } from './Account';
import { PaymentIntent, SetupIntent } from '@stripe/stripe-js';
import { Customer } from './Customer';
import { PropertyExtraCost } from './PropertyExtraCost';


export class BookingCosts {
    amount: number;
    stripe_commission: number;
    tourmie_commission: number;
    partner_commission: number;
    provider_revenue: number;

    base_price?: number;

    extra_amenities_price?: number;
    extra_property_utilities_price?: number;
    extra_costs_price?: number;
    tax_amount?: number;

    damage_deposit_price?: number;


    constructor(props?: BookingCosts) {

        this.amount = props?.amount || null;
        this.stripe_commission = props?.stripe_commission || null;
        this.tourmie_commission = props?.tourmie_commission || null;
        this.partner_commission = props?.partner_commission || null;
        this.provider_revenue = props?.provider_revenue || null;
        this.base_price = props?.base_price || null;
        this.extra_amenities_price = props?.extra_amenities_price || 0;
        this.extra_property_utilities_price = props?.extra_property_utilities_price || 0;
        this.extra_costs_price = props?.extra_costs_price || 0;
        this.tax_amount = props?.tax_amount || 0;
        this.damage_deposit_price = props?.damage_deposit_price || null;

    }
}

export interface BookingFunds {
    amount: number;
    stripe_commission: number;
    tourmie_commission: number;

    partner_commission: number;
    partner_commission_percent: number;

    provider_revenue: number;
}

export interface BookingFundsAfterRefund {
    amount: number;
    stripe_commission: number;
    tourmie_commission: number;
    partner_commission: number;
    provider_revenue: number;
    refund_amount: number;
}



export class BookingStripeData {
    amount: number;
    billing: {
        business_invoice?: boolean;
        business_invoice_tax_id?: string;
        business_invoice_tax_id_type?: string;
        name: string;
        address: string;
        city: string;
        postal_code: string;
        state: string;
        country: string;
        email: string;
        mobile_number: string;
    };

    paymentIntent?: PaymentIntent;
    setupIntent?: SetupIntent;
    customer?: any; // Stripe Customer

    constructor() {
        this.amount = null;

        this.billing = {
            business_invoice: false,
            business_invoice_tax_id: null,
            business_invoice_tax_id_type: null,
            name: null,
            email: null,
            mobile_number: null,
            address: null,
            city: null,
            state: null,
            postal_code: null,
            country: null
        };

        this.paymentIntent = null;
        this.customer = null;
    }
}



export class BookingSubmission { // data from client to back end for booking submission
    // accommodationBooking: AccommodationBooking;
    serviceBooking: Booking;
    service: Property;
    stripeData: BookingStripeData;

    constructor() {
        // this.accommodationBooking = null;
        this.serviceBooking = null;
        this.service = null;
        this.stripeData = null;
    }
}



export class BookingPaymentInstallment {
    id?: number;
    booking_id?: string;
    service_id?: string;
    customer_id?: string;
    provider_id?: string;
    service_type?: 'accommodation';
    start_date?: Date;
    amount?: number;
    paid?: boolean;
    payment_id?: string;
    payment_meta?: string;
    payment_status?:
        'paid'
        | 'canceled'
        | 'pending'
        | 'requires_payment_method' // Stripe PaymentIntent status
        | 'requires_confirmation' // Stripe PaymentIntent status
        | 'requires_action' // Stripe PaymentIntent status
        | 'processing' // Stripe PaymentIntent status
        | 'requires_capture' // Stripe PaymentIntent status
        | 'succeeded'; // Stripe PaymentIntent status
    paid_at?: Date;
    canceled?: boolean;
    canceled_at?: Date;
    refund_data?: string;

    // extras
    items?: BookingPaymentInstallmentItem[];

    constructor(props?: BookingPaymentInstallment) {
        this.id = props?.id || null;
        this.booking_id = props?.booking_id || null;
        this.service_id = props?.service_id || null;
        this.customer_id = props?.customer_id || null;
        this.provider_id = props?.provider_id || null;
        this.service_type = 'accommodation';
        this.start_date = props?.start_date || null;
        this.amount = props?.amount || 0;
        this.paid = props?.paid || false;
        this.payment_id = props?.payment_id || null;
        this.payment_meta = props?.payment_meta || null;
        this.payment_status = props?.payment_status || null;
        this.paid_at = null;
        this.canceled = props?.canceled || false;
        this.canceled_at = props?.canceled_at || null;
        this.refund_data = props?.refund_data || null;
        this.items = props?.items || [];
    }

}


export class BookingPaymentInstallmentItem {
    id?: number;
    installment_id?: number;
    service_id?: string;
    service_type?: string;
    type?: 'accommodation_cost' | 'utilities_expenses' | 'extra_cost' | 'extra_amenities' | 'tax_amount';
    start_date?: Date;
    amount?: number;
    stripe_commission?: number;
    stripe_commission_percent?: number;
    tourmie_commission?: number;
    tourmie_commission_percent?: number;
    taxable?: boolean;
    has_tourmie_commission?: boolean;

    constructor(props?: BookingPaymentInstallmentItem) {
        this.id = props?.id || null;
        this.installment_id = props?.installment_id || null;
        this.service_id = props?.service_id || null;
        this.service_type = props?.service_type || null;
        this.type = props?.type || null;
        this.start_date = props?.start_date || null;
        this.amount = props?.amount || 0;
        this.stripe_commission = props?.stripe_commission || 0;
        this.stripe_commission_percent = props?.stripe_commission_percent || 0;
        this.tourmie_commission = props?.tourmie_commission || 0;
        this.tourmie_commission_percent = props?.tourmie_commission_percent || 0;
        this.taxable = props?.taxable || false;
        this.has_tourmie_commission = props?.has_tourmie_commission || false;
    }

}



export class Booking {

    // In DB
    id?: number;
    booking_id?: string;
    customer_id: string;
    provider_id: string;
    partner_id?: string;
    service_id: string;
    service_type: 'accommodation';
    service_booking_type?: 'direct' | 'request';
    service_category?: 'private_room' | 'entire_place';
    service_subcategory?: 'house' | 'apartment' | 'loft' | 'villa' | 'room' | 'studio' | 'penthouse' | 'maisonette';
    status: 'confirmed' | 'archived' | 'pending' | 'canceled' | 'rejected';
    booking_channel?: string; // 'airbnb.com' | 'booking.com' | 'business_website' | 'vrbo' | 'expedia' | 'phone_call' | 'tourmie_stay' | 'other';
    booking_source?: string; // 'airbnb.com' | 'booking.com' | 'business_website' | 'vrbo' | 'expedia' | 'phone_call' | 'tourmie_stay' | 'other';
    booking_external_id?: string; // for accommodation bookings from 'airbnb.com', 'booking.com', 'business_website', 'vrbo', 'expedia'
    amount?: number;
    stripe_commission?: number;
    tourmie_commission?: number;
    partner_commission?: number;
    provider_revenue?: number;
    service_price?: number;
    tax_amount?: number; // total booking tax amount
    tax_percent?: number;
    tax_type?: 'no_tax' | 'tax_percent' | 'tax_amount_per_person_per_day';
    payment_id?: string;
    payment_status?:
        'paid'
        | 'canceled'
        | 'pending'
        | 'requires_payment_method' // Stripe PaymentIntent status
        | 'requires_confirmation' // Stripe PaymentIntent status
        | 'requires_action' // Stripe PaymentIntent status
        | 'processing' // Stripe PaymentIntent status
        | 'requires_capture' // Stripe PaymentIntent status
        | 'succeeded'; // Stripe PaymentIntent status

    payment_platform?: 'stripe' | 'offline';
    cancelation_policy?: string;
    earnings_transfered?: boolean;
    earnings_transfered_at?: Date;
    partner_commission_transfered?: boolean;
    partner_commission_transfered_at?: Date;
    partner_commission_percent?: number;
    accommodation_booking_id?: string;
    property_id?: string;
    start_area?: string; // pickup location (car rentals & transfers)
    start_address_name?: string;
    start_address_formatted?: string;
    start_address_state?: string;
    start_address_city?: string;
    start_address_country?: string;
    start_latitude?: number;
    start_longitude?: number;
    start_date: string | Date;
    start_date_str: string | Date; // YYYY-MM-DD
    start_time?: string | Date;
    start_time_str?: string; // HH:mm
    end_address_name?: string;
    end_address_formatted?: string;
    end_address_state?: string;
    end_address_city?: string;
    end_address_country?: string;
    end_latitude?: number;
    end_longitude?: number;
    end_date?: string | Date;
    end_date_str?: string; // YYYY-MM-DD
    end_time?: string | Date;
    end_time_str?: string; // HH:mm
    end_area?: string; // return/destination location (car rentals & transfers)
    arrival_time?: string | Date;
    departure_time?: string | Date;
    duration_in_days?: number;
    driver_date_of_birth?: string | Date;
    driver_license_number?: string;
    driver_license_country?: string;
    driver_license_issued_at?: Date;
    driver_license_number_international?: string;
    drivers?: number;
    contact_person_name?: string;
    contact_person_phone?: string;
    billing_name?: string;
    billing_address?: string;
    billing_city?: string;
    billing_postal_code?: string;
    billing_state?: string;
    billing_country?: string;
    billing_email?: string;
    billing_mobile_number?: string;
    billing_business_invoice?: boolean;
    billing_business_invoice_tax_id?: string;
    billing_business_invoice_tax_id_type?: string;
    comments?: string;
    refund_data?: string;
    reviewed_by_customer?: boolean; // true if customer reviewed this booking
    invited_by_host?: boolean;
    invited_by_host_at?: Date;
    joined?: boolean;
    joined_at?: Date; // the date visitor joined the app
    expired?: boolean; // visitor's stay in this accommodation Completed
    canceled?: boolean;
    canceled_by?: string;
    canceled_at?: Date;
    cancelation_reason?: string;
    archived?: boolean;
    archived_at?: Date;
    created_at?: Date;
    persons?: number;
    adults?: number;
    children?: number;
    infants?: number;
    total_persons?: number;
    inhouse?: boolean;

    // Extras (not in db)
    costs?: BookingCosts;
    customer?: Customer;
    provider?: Account;
    service?: Property;
    is_passed?: boolean;
    property: Property;
    lease_agreement: string;
    lease_agreement_url: string;

    extra_amenities?: Amenity[];
    extra_costs?: PropertyExtraCost[];
    payment_installments?: BookingPaymentInstallment[];
    pay_in_installments?: boolean;


    constructor(props?: Booking) {

        this.booking_id = props?.booking_id || null;
        this.customer_id = props?.customer_id || null;
        this.provider_id = props?.provider_id || null;
        this.partner_id = props?.partner_id || null;
        this.service_id = props?.service_id || null;
        this.service_type = props?.service_type || 'accommodation';
        this.service_category = props?.service_category || null;
        this.service_booking_type = props?.service_booking_type || null;
        this.service_subcategory = props?.service_subcategory || null;
        this.status = props?.status || null;
        this.amount = props?.amount || 0;
        this.stripe_commission = props?.stripe_commission || 0;
        this.tourmie_commission = props?.tourmie_commission || 0;
        this.partner_commission = props?.partner_commission || 0;
        this.provider_revenue = props?.provider_revenue || 0;
        this.tax_amount = props?.tax_amount || 0;
        this.tax_percent = props?.tax_percent || 0;
        this.tax_type = props?.tax_type || null;
        this.payment_id = props?.payment_id || null;
        this.payment_status = props?.payment_status || null;
        this.payment_platform = props?.payment_platform || null;
        this.cancelation_policy = props?.cancelation_policy || null;
        this.earnings_transfered = props?.earnings_transfered || false;
        this.earnings_transfered_at = props?.earnings_transfered_at || null;
        this.partner_commission_transfered = props?.partner_commission_transfered || false;
        this.partner_commission_transfered_at = props?.partner_commission_transfered_at || null;
        this.booking_channel = props?.booking_channel || null;
        this.booking_source = props?.booking_source || null;
        this.booking_external_id = props?.booking_external_id || null;
        this.accommodation_booking_id = props?.accommodation_booking_id || null;
        this.property_id = props?.property_id || null;
        this.partner_commission_percent = props?.partner_commission_percent || 0;
        this.service_price = props?.service_price || 0;
        this.start_area = props?.start_area || null;
        this.start_address_name = props?.start_address_name || null;
        this.start_address_formatted = props?.start_address_formatted || null;
        this.start_address_state = props?.start_address_state || null;
        this.start_address_city = props?.start_address_city || null;
        this.start_address_country = props?.start_address_country || null;
        this.start_latitude = props?.start_latitude || null;
        this.start_longitude = props?.start_longitude || null;
        this.start_date = props?.start_date || null;
        this.start_date_str = props?.start_date_str || null;
        this.start_time = props?.start_time || null;
        this.start_time_str = props?.start_time_str || null;
        this.end_address_name = props?.end_address_name || null;
        this.end_address_formatted = props?.end_address_formatted || null;
        this.end_address_state = props?.end_address_state || null;
        this.end_address_city = props?.end_address_city || null;
        this.end_address_country = props?.end_address_country || null;
        this.end_latitude = props?.end_latitude || null;
        this.end_longitude = props?.end_longitude || null;
        this.end_date = props?.end_date || null;
        this.end_date_str = props?.end_date_str || null;
        this.end_time = props?.end_time || null;
        this.end_time_str = props?.end_time_str || null;
        this.end_area = props?.end_area || null;
        this.arrival_time = props?.arrival_time || null;
        this.departure_time = props?.departure_time || null;
        this.duration_in_days = props?.duration_in_days || null;
        this.driver_date_of_birth = props?.driver_date_of_birth || null;
        this.driver_license_number = props?.driver_license_number || null;
        this.driver_license_country = props?.driver_license_country || null;
        this.driver_license_issued_at = props?.driver_license_issued_at || null;
        this.driver_license_number_international = props?.driver_license_number_international || null;
        this.drivers = props?.drivers || null;
        this.contact_person_name = props?.contact_person_name || null;
        this.contact_person_phone = props?.contact_person_phone || null;
        this.billing_name = props?.billing_name || null;
        this.billing_address = props?.billing_address || null;
        this.billing_city = props?.billing_city || null;
        this.billing_postal_code = props?.billing_postal_code || null;
        this.billing_state = props?.billing_state || null;
        this.billing_country = props?.billing_country || null;
        this.billing_email = props?.billing_email || null;
        this.billing_mobile_number = props?.billing_mobile_number || null;
        this.billing_business_invoice = props?.billing_business_invoice || false;
        this.billing_business_invoice_tax_id = props?.billing_business_invoice_tax_id || null;
        this.billing_business_invoice_tax_id_type = props?.billing_business_invoice_tax_id_type || null;
        this.comments = props?.comments || null;
        this.refund_data = props?.refund_data || null;
        this.reviewed_by_customer = props?.reviewed_by_customer || false;
        this.invited_by_host = props?.invited_by_host || false;
        this.invited_by_host_at = props?.invited_by_host_at || null;
        this.joined = props?.joined || false;
        this.joined_at = props?.joined_at || null;
        this.expired = props?.expired || false;
        this.canceled = props?.canceled || false;
        this.canceled_by = props?.canceled_by || null;
        this.canceled_at = props?.canceled_at || null;
        this.cancelation_reason = props?.cancelation_reason || null;
        this.archived = props?.archived || null;
        this.archived_at = props?.archived_at || null;
        this.created_at = props?.created_at || null;
        this.persons = props?.persons || 0;
        this.adults = props?.adults || 0;
        this.children = props?.children || 0;
        this.infants = props?.infants || 0;
        this.total_persons = props?.total_persons || null;
        this.inhouse = props?.inhouse || false;
        this.lease_agreement_url = props?.lease_agreement_url || null;


        // Extras (not in DB table)
        this.customer = props?.customer || new Customer();
        this.provider = props?.provider || null;
        this.service = props?.service || null;
        this.is_passed = props?.is_passed || false;
        this.extra_amenities = props?.extra_amenities || [];
        this.extra_costs = props?.extra_costs || [];
        this.payment_installments = props?.payment_installments || [];
        this.pay_in_installments = props?.pay_in_installments || false;
        this.costs = props?.costs || {
            amount: 0,
            stripe_commission: 0,
            tourmie_commission: 0,
            partner_commission: 0,
            provider_revenue: 0,

            base_price: 0,
            extra_amenities_price: 0,
            extra_costs_price: 0,
        };
        this.lease_agreement = props?.lease_agreement || null;




    }
}

